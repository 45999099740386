import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { DataService } from '@teacherapp_core/services/data.service';
import { LocalService } from '@teacherapp_core/services/local.service';
import { PermissionHandlerService } from '@teacherapp_core/services/permission-handler.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeamLevelPermissionResolver implements Resolve<any> {

  userId = 0;
  selectedOrganizationId = 0;
  selectedTeamId = 0;

  constructor(
    private router: Router,
    private localService: LocalService,
    private dataService: DataService,
    private permissionHandlerService: PermissionHandlerService,
  ) { }
  
  createApiParams() {
    const params: any = {
      user_id: this.userId,
      org_id: this.selectedOrganizationId,
      team_id: this.selectedTeamId,
    };
    return params;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.userId = this.localService.getUserIdLocalStorage();
    if (!this.userId) {
      return of(null);
    }

    this.selectedOrganizationId = +route.queryParams.organization_id;
    this.selectedTeamId = +route.queryParams.team_id;
    if (!this.selectedOrganizationId) {
      return of(null);
    }
    
    if (!this.selectedTeamId) {
      return of(null);
    }
    
    const _last_active_org_id = this.dataService.lastSelectedOrganizationId.value;
    const _last_active_team_id = this.dataService.lastSelectedTeamId.value;
    // console.log({ oid: this.selectedOrganizationId, tid: this.selectedTeamId, _last_active_org_id, _last_active_team_id})
    if ((_last_active_org_id === this.selectedOrganizationId) && (_last_active_team_id === this.selectedTeamId)) {
      return of(null);
    }

    // if(this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.skipCourseDetailResolver) {
    //   return of(null);
    // }

    let data: any = this.createApiParams();
    return this.permissionHandlerService.fetchMyPermissionsAsTeamMember(data).pipe(
      map((response: any) => {
        if (response.auth_group) {
          this.permissionHandlerService.formatAndUpdateSpecificTeamRelatedPermissionWithAPiResponse(response.auth_group);
        }
        else {
          this.permissionHandlerService.resetAndUpdateSpecificTeamRelatedPermissionWithAPiResponse();
        }
        if (response.team) {
          this.permissionHandlerService.updateCurrentSelectedTeamConfig(response.team);
        }
        else {
          this.permissionHandlerService.updateCurrentSelectedTeamConfig(null);
        }
        return { data: response, error: null };
      }),
      catchError((error: any) => {
        this.permissionHandlerService.resetAndUpdateSpecificTeamRelatedPermissionWithAPiResponse();
        this.permissionHandlerService.updateCurrentSelectedTeamConfig(null);
        return of({ data: [], error: error })
      })
    );
  }
}
