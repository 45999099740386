import { IPageModuleGroup, IPageModuleOperation, ITeamLevel } from "@teacherapp_core/interfaces/permission-setting.interface";

export const TEAM_LEVEL_PAGE_MODULE_GROUP_MAP: {[key in IPageModuleGroup]: number[]} = {
    Organization: [1],
    Org_Branches: [1],
    Org_Staffs: [1],
    ORGANIZATION_STUDENT: [1],
    ORGANIZATION_GRADE: [1],
    ORGANIZATION_SUBJECT: [1],
    ORGANIZATION_TEAM: [1, 2, 3, 4, 5, 6],
    ORGANIZATION_AUTH_GROUP: [1],
    Courses: [1, 2, 6],
    BATCHES: [1, 2, 3, 4, 6],
    Subjects: [1, 2, 3, 4, 6],
    Classes: [1, 2, 3, 4, 5, 6],
    COURSE_ASSIGNMENT: [1, 2, 3, 4, 5, 6],
    LIBRARY: [1, 2, 3, 4, 5, 6],
};

export const TEAM_LEVEL_PAGE_MODULE_OPERATION_MAP: {[key in IPageModuleOperation]: number[]} = {
    ORG_MANAGEMENT: [1],
    ORG_BRANCH_MANAGEMENT: [1],
    STAFF_ORG: [1],
    ORG_STUDENT_MANAGEMENT: [1],
    ORG_GRADE_MANAGEMENT: [1],
    ORG_SUBJECT_MANAGEMENT: [1],
    ORG_SUBJECT_CATEGORY_MANAGEMENT: [1],
    ORG_TEAM_MANAGEMENT: [1, 2, 3, 4, 5, 6],
    ORG_TEAM_MEMBER_MANAGEMENT: [1, 2, 3, 4, 5, 6],
    ORG_AUTH_GROUP_MANAGEMENT: [1],
    COURSE_TYPE_MANAGEMENT: [1],
    COURSE_MANAGEMENT: [1, 2, 6],
    COURSE_CLONE_MANAGEMENT: [1, 2, 6],
    BATCH_MANAGEMENT: [1, 2, 3, 6],
    BATCH_CLONE_MANAGEMENT: [1, 2, 3, 6],
    BATCH_DESIGN_MANAGEMENT: [1, 2, 3, 4, 6],
    BATCH_TEMPLATE_MANAGEMENT: [1, 2, 3, 4, 6],
    SUBJECT_MANAGEMENT: [1, 2, 3, 4, 6],
    SUBJECT_CLONE_MANAGEMENT: [1, 2, 3, 4, 6],
    CLASS_MANAGEMENT: [1, 2, 3, 4, 5, 6],
    COURSE_ASSIGNMENT_MANAGEMENT: [1, 2, 3, 4, 5, 6],
    ASSIGNMENT_CONTENT_MANAGEMENT: [1, 2, 3, 4, 5, 6],
    LEARNINGPATH_MANAGEMENT: [1, 2, 3, 4, 5, 6],
    SUPERBOOK_MANAGEMENT: [1, 2, 3, 4, 5, 6],
    MAINTOPIC_MANAGEMENT: [1, 2, 3, 4, 5, 6],
    SUBTOPIC_MANAGEMENT: [1, 2, 3, 4, 5, 6],
    LECTURE_MANAGEMENT: [1, 2, 3, 4, 5, 6],
    LECTURE_COMPONENT_MANAGEMENT: [1, 2, 3, 4, 5, 6],
    QUESTION_FOLDER_MANAGEMENT: [1, 2, 3, 4, 5, 6],
};

export const TEAM_LEVEL_DISPLAY_NAME_MAP: {[key in ITeamLevel]?: string} = {
    1: 'Organization Level Team',
    2: 'Branch Level Team',
    3: 'Course Level Team',
    4: 'Batch Level Team',
    5: 'Batch Subject Level Team',
    6: 'Common Level Team'
};

export const PARENT_TEAM_LEVEL_WARNING_SUB_TEAM: {[key in ITeamLevel]?: string} = {
    1: 'Parent team contains organization filter, must select organization as a team filter.',
    2: 'Parent team contains branch filter , must select branch as a team filter.',
    3: 'Parent team contains course filter, must select course as a team filter.',
    4: 'Parent team contains batch filter, must select batch as a team filter.',
    5: 'Parent team contains batch subject filter, must select batch subject as a team filter.',
};

export const CHANNEL_POSSIBLE_FILTER_BY_TEAM_LEVEL: {[key in ITeamLevel]?: any[]} = {
    1: [ 'branch_id', 'course_id', 'batch_id', 'subject_id', 'grade_id', 'grade_subject_id' ],
    2: [ 'branch_id', 'course_id', 'batch_id', 'subject_id', 'grade_id', 'grade_subject_id' ],
    3: [ 'course_id', 'batch_id', 'subject_id', 'grade_id', 'grade_subject_id' ],
    4: [ 'batch_id', 'subject_id', 'grade_id', 'grade_subject_id' ],
    5: [ 'subject_id', 'grade_id', 'grade_subject_id' ],
    6: [ 'branch_id', 'course_id', 'batch_id', 'subject_id', 'grade_id', 'grade_subject_id' ]
};

