export const USER_DATA_STORAGE_NAME = 'CurrentUser';
export const USER_ORG_DATA_STORAGE_NAME = 'CurrentUserOrg';
export const USER_TEAM_DATA_STORAGE_NAME = 'CurrentUserTeam';
export const CLASS_PAGE_URL_STORAGE_NAME = 'CLASS_LAST_VISITED_PAGE';
export const QF_Q_COPY_MODE_STORAGE_NAME = 'QF_Q_COPY_MODE';

export const DEFAULT_ERROR_MESSAGE_API_ERROR = 'Something went wrong. Please try again later.';
export const DEFAULT_ERROR_MESSAGE_CORS_ERROR = 'Server is not reachable.';
export const DEFAULT_FORM_INVALID_ERROR = 'Please check all form fields, may be some fields are empty or validation failed. Form is invalid.';
export const DEFAULT_INVALID_LINK_ID_ERROR = 'Please enter valid id to continue.';
export const VIEW_PERMISSION_NOT_ALLOWED = `You don't have permission to view this item.`;
export const EDIT_PERMISSION_NOT_ALLOWED = `You don't have permission to edit this item.`;
export const ADD_PERMISSION_NOT_ALLOWED = `You don't have permission to add new item.`;
export const HOME_PAGE_PATH = 'teaching-management';
export const WHITEBORAD_DEFAULT_WIDTH = 1366;
export const WHITEBORAD_DEFAULT_HEIGHT = 768;
export const NUMERIC_REG_EXP = /^\d*(?:\.\d{1,2})?$/;
export const NUMERIC_POSITIVE_INTEGER_REG_EXP = /^\d+$/;
export const NUMERIC_POSITIVE_INTEGER_EXCEPT_ZERO_REG_EXP = /^[1-9]\d*$/;

export const TEACHER_DEFAULT_IMAGE = 'assets/images/user.png';
export const TEACHER_BIG_CARD_DEFAULT_IMAGE = 'assets/images/teacher-male-default-photo.png';
export const TEACHER_BIG_CARD_DEFAULT_FEMALE_IMAGE = 'assets/images/teacher-female-default-photo.png';
export const STUDENT_DEFAULT_IMAGE = 'assets/images/user.png';
export const MAIN_TOPIC_DEFAULT_IMAGE = 'assets/images/science.png';
export const ASSIGNMENT_DEFAULT_IMAGE = 'assets/images/assignment-icon.png';

export const CURRENCY_LIST = [
    {
        id: 'INR',
        name: 'INR'
    },
    {
        id: 'SGD',
        name: 'SGD'
    },
    {
        id: 'USD',
        name: 'USD'
    }
];

export const TEACHING_LANGUAGES_LIST = [
    {
        id: 'English',
        name: 'English'
    },
    {
        id: 'Malay',
        name: 'Malay'
    },
    {
        id: 'Chinese',
        name: 'Chinese'
    },
    {
        id: 'Tamil',
        name: 'Tamil'
    }
];

export const ORGANIZATION_TYPES_LIST = [
    {
        id: 'school',
        name: 'School'
    },
    {
        id: 'tutioncenter',
        name: 'Tution Center'
    },
    {
        id: 'others',
        name: 'Others'
    }
];

export enum ORGANIZATION_TYPES_ENUM {
    school = 'School',
    tutioncenter = 'Tution Center',
    others = 'Others',
};

export const ORGANIZATION_USER_LEVELS = [
    {
        id: 1,
        name: 'Organization Level Team'
    },
    {
        id: 2,
        name: 'Branch Level Team'
    },
    {
        id: 3,
        name: 'Course Level Team'
    },
    {
        id: 4,
        name: 'Batch Level Team'
    },
    {
        id: 5,
        name: 'Batch Subject Level Team'
    },
    {
        id: 6,
        name: 'Common Level Team'
    }
];

export const ORGANIZATION_USER_ROLES = [
    {
        id: 'admin',
        name: 'Admin'
    },
    {
        id: 'manager',
        name: 'Manager'
    }
];

export const SUPER_ADMIN_ROLE_KEY = 'SuperAdmin';

export const ORGANIZATION_STAFF_ROLES = [
    {
        id: SUPER_ADMIN_ROLE_KEY,
        name: 'Super Admin'
    },
    {
        id: 'Admin',
        name: 'Admin'
    },
    {
        id: 'Others',
        name: 'Member'
    }
];

export enum ORGANIZATION_STAFF_ROLE_ENUM {
    SuperAdmin = 'Super Admin',
    Admin = 'Admin',
    Others = 'Member',
    Teacher = 'Teacher',
};

export const BRANCH_USER_ROLES = [
    {
        id: 'admin',
        name: 'Admin'
    },
    {
        id: 'manager',
        name: 'Manager'
    },
    {
        id: 'teacher',
        name: 'Teacher'
    },
    {
        id: 'hod',
        name: 'HOD'
    }
];

export enum ORGANIZATION_BRANCH_USER_ROLE_ENUM {
    admin = 'Admin',
    manager = 'Manager',
    teacher = 'Teacher',
    hod = 'HOD',
};

export const LIVE_COURSE_BATCH_TYPE = [
    {
        id: 'smallgroup',
        name: 'Small Group Class'
    },
    {
        id: 'webinar',
        name: 'Webinar'
    },
    {
        id: 'one_one_class',
        name: 'One to One Class'
    }
];

export enum LIVE_COURSE_BATCH_TYPE_ENUM {
    smallgroup = 'Small Group Class',
    webinar = 'Webinar',
    one_one_class = 'One to One Class',
};

export const LIVE_CLASS_RECORDING_TYPE = [
    {
        id: 'SD',
        name: 'SD (720p 30fps)'
    },
    {
        id: 'HD',
        name: 'HD (1080p 30fps)'
    },
    {
        id: 'FHD',
        name: 'FHD (1080p 60fps)'
    },
    {
        id: 'NONE',
        name: 'None'
    }
];

export enum LIVE_CLASS_RECORDING_TYPE_ENUM {
    SD = 'SD (720p 30fps)',
    HD = 'HD (1080p 30fps)',
    FHD = 'FHD (1080p 60fps)',
    NONE = 'None'
};

export const COURSE_LAYOUT_TYPES = [
    {
        id: 'one_column',
        name: 'One Column View',
        previewImage: 'assets/images/one-column-lg.png',
    },
    {
        id: 'one_column_sm',
        name: 'One Column Small View',
        previewImage: 'assets/images/one-column-sm.png',
    },
    {
        id: 'two_column',
        name: 'Two Column View',
        previewImage: 'assets/images/two-column-lg.png',
    },
    {
        id: 'two_column_sm',
        name: 'Two Column Small View',
        previewImage: 'assets/images/two-column-sm.png',
    },
    {
        id: 'image_only',
        name: 'Image Only View',
        previewImage: 'assets/images/image-preview.png',
    }
];

export enum COURSE_LAYOUT_TYPES_ENUM {
    one_column = 'One Column View',
    one_column_sm = 'One Column Small View',
    two_column = 'Two Column View',
    two_column_sm = 'Two Column Small View',
    image_only = 'Image Only View',
};

export const LIVE_COURSE_BATCH_PAYMENT_TERMS = [
    {
        id: 'course',
        name: 'Course'
    },
    {
        id: 'month',
        name: 'Month'
    },
    {
        id: 'year',
        name: 'Year'
    }
];

export enum LIVE_COURSE_BATCH_PAYMENT_TERMS_ENUM {
    course = 'Course',
    month = 'Month',
    year = 'Year',
};

export const LIVE_COURSE_BATCH_STATUSES_LIST = [
    {
        id: 'not_started',
        name: 'Not Started'
    },
    {
        id: 'ongoing',
        name: 'Live'
    },
    {
        id: 'ended',
        name: 'Completed'
    }
];

export enum LIVE_COURSE_BATCH_STATUS_ENUM {
    ongoing = 'Live',
    not_started = 'Not Started',
    ended = 'Completed',
};

export const VIDEO_PROFILE_360p = {
    width: {
        max: 480,
    },
    height: {
        max: 360,
    },
    frameRate: 15,
    bitrateMin: 320,
    bitrateMax: 320,
};

export const VIDEO_PROFILE_720p = {
    width: {
        max: 1280,
    },
    height: {
        max: 720,
    },
    frameRate: 30,
    bitrateMin: 2000,
    bitrateMax: 2000,
};

export const VIDEO_PROFILE_1080p = {
    width: {
        max: 1920,
    },
    height: {
        max: 1080,
    },
    frameRate: 30,
    bitrateMin: 3150,
    bitrateMax: 3150,
};

export const VIDEO_PROFILE_1080p_1 = {
    width: {
        max: 1920,
    },
    height: {
        max: 1080,
    },
    frameRate: 60,
    bitrateMin: 4780,
    bitrateMax: 4780,
};

export const LIVE_CLASS_RECORDING_TYPE_RESOLUTION: {[key: string]: any} = {
    SD: {
        audioProfile: 'music_standard',
        videoProfile: VIDEO_PROFILE_360p,
        screenProfile: VIDEO_PROFILE_720p,
    },
    HD: {
        audioProfile: 'high_quality',
        videoProfile: VIDEO_PROFILE_360p,
        screenProfile: VIDEO_PROFILE_1080p,
    },
    FHD: {
        audioProfile: 'high_quality_stereo',
        videoProfile: VIDEO_PROFILE_360p,
        screenProfile: VIDEO_PROFILE_1080p_1,
    },
    NONE: {
        audioProfile: 'music_standard',
        videoProfile: VIDEO_PROFILE_360p,
        screenProfile: VIDEO_PROFILE_1080p,
    }
};

export const DAY_LIST = [
    {
        id: 'monday',
        name: 'Monday',
        weight: 1,
    },
    {
        id: 'tuesday',
        name: 'Tuesday',
        weight: 2,
    },
    {
        id: 'wednesday',
        name: 'Wednesday',
        weight: 3,
    },
    {
        id: 'thursday',
        name: 'Thursday',
        weight: 4,
    },
    {
        id: 'friday',
        name: 'Friday',
        weight: 5,
    },
    {
        id: 'saturday',
        name: 'Saturday',
        weight: 6,
    },
    {
        id: 'sunday',
        name: 'Sunday',
        weight: 0,
    },
];

export const CLASS_PURPOSES_LIST = [
    {
        id: 'normal_class',
        name: 'Normal Class'
    },
    {
        id: 'discussion',
        name: 'Discussion'
    },
    {
        id: 'post_class_support',
        name: 'Post Class Support'
    }
];

export enum CLASS_PURPOSES_ENUM {
    normal_class = 'Normal Class',
    discussion = 'Discussion',
    post_class_support = 'Post Class Support',
};

export const RECURRING_TYPES_LIST = [
    {
        id: 'daily',
        name: 'Daily'
    },
    {
        id: 'weekly',
        name: 'Weekly'
    },
    // {
    //     id: 'monthly',
    //     name: 'Monthly'
    // }
];

export const RECURRING_FREQ_TYPE_LIST = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
];

export const BATCH_STATUS_INFO = {
    ongoing: 'Active',
    not_started: 'Not Started',
    ended: 'Completed'
};

export const ORG_STUDENT_STATUS = [
    { id: 'active', name: "Active", cssClass: 'active' },
    { id: 'inactive', name: "Inactive", cssClass: 'danger' },
];

export const WHITEBOARD_TOOLS_SPECIFICATION = [
    {
        applianceType: 'NORMAL',
        type: 'clicker',
        name: 'Clicker',
        icon: 'assets/images/svg/cursor.svg',
    },
    {
        applianceType: 'NORMAL',
        type: 'selector',
        name: 'Selector',
        icon: 'assets/images/svg/selection.svg',
    },
    {
        applianceType: 'SHAPE_DROP',
        type: 'shape',
        name: 'Shape',
        icon: 'assets/images/svg/shape.svg',
        options: [
            {
                type: 'arrow',
                name: 'arrow',
                icon: 'assets/images/svg/arrow.svg',
            },
            {
                type: 'rectangle',
                name: 'rectangle',
                icon: 'assets/images/svg/rectangle.svg',
            },
            {
                type: 'ellipse',
                name: 'ellipse',
                icon: 'assets/images/svg/circle.svg',
            },
            {
                type: 'straight',
                name: 'Straight line',
                icon: 'assets/images/svg/line.svg',
            },
            {
                type: 'shape',
                shapeType: 'pentagram',
                name: 'Pentagram',
                icon: 'assets/images/svg/pentagram.svg',
            },
            {
                type: 'shape',
                shapeType: 'rhombus',
                name: 'Rhombus',
                icon: 'assets/images/svg/rhombus.svg',
            },
            {
                type: 'shape',
                shapeType: 'speechBalloon',
                name: 'Speech balloon',
                icon: 'assets/images/svg/speech-balloon.svg',
            },
            {
                type: 'shape',
                shapeType: 'triangle',
                name: 'Triangle',
                icon: 'assets/images/svg/triangle.svg',
            },
        ]
    },
    {
        applianceType: 'NORMAL',
        type: 'pencil',
        name: 'Pencil',
        icon: 'assets/images/svg/pencil.svg',
    },
    {
        applianceType: 'NORMAL',
        type: 'eraser',
        name: 'Eraser',
        icon: 'assets/images/svg/ereser.svg',
    },
    {
        applianceType: 'NORMAL',
        type: 'clear',
        name: 'Clear Scene',
        icon: 'assets/images/svg/clear.svg',
    },
    {
        applianceType: 'NORMAL',
        type: 'text',
        name: 'Text',
        icon: 'assets/images/svg/text.svg',
    },
    {
        applianceType: 'NORMAL',
        type: 'hand',
        name: 'Hand',
        icon: 'assets/images/svg/hand-tool.svg',
    },
    {
        applianceType: 'NORMAL',
        type: 'laserPointer',
        name: 'Laser',
        icon: 'assets/images/svg/laser.svg',
    },
    {
        applianceType: 'COLOR_SELECT',
        type: 'color',
        name: 'Color',
        icon: null,
    },
];

export const WHITEBOARD_COLOR_PRESET_OPTION = [ '#ec3455', '#f5ad46', '#60e8c6', '#57b2fc', '#005bf6', '#9e51b6', '#68ab5d', '#1d2129', '#fb90d0' ];

export const VIRTUAL_BACKGROUND_LIST = [
    {
        id: 'NONE',
        type: 'NONE',
        title: 'No background',
        image: 'assets/images/bg-1.jpg',
    },
    {
        id: 'BLUR',
        type: 'BLUR',
        title: 'Blur',
        image: 'assets/images/bg-5.jpg',
    },
    {
        id: 'IMAGE_1',
        type: 'IMAGE_1',
        title: 'Select Image',
        image: 'assets/images/bg-2.jpg',
    },
];

export const ORGANIZATION_TEAM_MEMBER_ACCESS_LEVEL = [
    {
        id: 'ALL',
        name: 'All Content'
    },
    {
        id: 'ASSIGNED',
        name: 'Only Assigned Content'
    },
    {
        id: 'MEMBERS',
        name: 'Member Only Content'
    },
];

export const ORGANIZATION_TEAM_MEMBER_ROLE_LEVEL = [
    {
        id: 'Manager',
        name: 'Manager'
    },
    {
        id: 'Member',
        name: 'Member'
    },
    {
        id: 'Viewer',
        name: 'Viewer'
    },
];

export enum ORGANIZATION_TEAM_MEMBER_ACCESS_LEVEL_ENUM {
    ALL = 'All Content',
    ASSIGNED = 'Only Assigned Content',
    MEMBERS = 'Member Only Content'
};

export const ORGANIZATION_TEAM_GROUP_LEVEL = [
    {
        id: 'Organization',
        name: 'Organization'
    },
    {
        id: 'Branch',
        name: 'Branch'
    },
    {
        id: 'Team',
        name: 'Team'
    },
];

export const CONTENT_OWNERSHIP_TYPE_OPTIONS = [
    { id: 1, type: 'open', name: 'My Content' },
    { id: 2, type: 'submit_today', name: 'Shared With Me' },
];

export const QUESTION_FOLDER_COPY_REQ_SUGGESTION = [
    { id: 'QUIZBANK', name: 'Quiz Bank' }
];

export const CHANNEL_MESSAGE_TYPE_OPTIONS = [
    { id: 'Chat', name: 'Chat', icon: 'assets/images/svg/msg-type/chat.svg', cssClass: 'w-4ch' },
    { id: 'Thread', name: 'Thread', icon: 'assets/images/svg/msg-type/thread.svg', cssClass: 'w-6ch' },
    { id: 'Announcement', name: 'Announcement', icon: 'assets/images/svg/msg-type/announcement.svg', cssClass: 'w-12ch' },
    { id: 'Doubts', name: 'Doubts', icon: 'assets/images/svg/msg-type/doubts.svg', cssClass: 'w-6ch' },
    { id: 'Feedback', name: 'Feedback', icon: 'assets/images/svg/msg-type/feedback1.svg', cssClass: 'w-8ch' },
];

export const CHANNEL_TYPE_OPTIONS = [
    { id: 'INTERNAL', name: 'Internal' },
    { id: 'EXTERNAL', name: 'External' },
    { id: 'ONE_TO_ONE', name: 'One to One' }
];

export const CHANNEL_STATUS_OPTIONS = [
    { id: 'Active', name: "Active", cssClass: 'active' },
    { id: 'Inactive', name: "Inactive", cssClass: 'danger' },
    { id: 'Deleted', name: "Deleted", cssClass: 'warning' },
];

export const CHANNEL_MEMBER_USER_TYPES_OPTIONS = [
    { id: 'STAFF', name: 'Staff', cssClass: '', icon: 'assets/images/svg/teacher-blue.svg' },
    { id: 'STUDENT', name: 'Student', cssClass: '', icon: 'assets/images/svg/student-blue.svg' },
    { id: 'PARENT', name: 'Parent', cssClass: '', icon: 'assets/images/svg/staff-icon.svg' },
];

export const CHANNEL_MEMBER_ROLE_EXCEPT_LEADER_OPTIONS = [
    // { id: 'LEADER', name: 'Leader' },
    { id: 'ADMIN', name: 'Admin', cssClass: '', icon: null, memberRoleChangeAllowed: ['ADMIN', 'MANAGER', 'MEMBER'], memberMoveAllowed: ['ADMIN', 'MANAGER', 'MEMBER'] },
    { id: 'MANAGER', name: 'Manager', cssClass: '', icon: null, memberRoleChangeAllowed: ['MANAGER', 'MEMBER'], memberMoveAllowed: ['MEMBER'] },
    { id: 'MEMBER', name: 'Member', cssClass: '', icon: null, memberRoleChangeAllowed: [], memberMoveAllowed: [] },
];

export const CHANNEL_MEMBER_FILTER_LEVEL_OPTIONS = [
    { id: 'TEAM_LEVEL_FILTER', name: "Team Level" },
    { id: 'CHANNEL_LEVEL_FILTER', name: "Channel Level" },
];

export const CHANNEL_MEMBER_STUDENT_SELECTION_OPTIONS = [
    { id: 'STUDENT', name: "Select Student Only" },
    { id: 'PARENT', name: "Select Parents / Guardians Only" },
    { id: 'BOTH', name: "Select Both Student & Parents / Guardians Only" },
];

export const USER_POSSIBLE_ONLINE_STATUS = [
    { id: 'Available', name: 'Available', cssClass: 'available', icon: 'assets/images/svg/status-svgs/available.svg' },
    { id: 'Busy', name: 'Busy', cssClass: 'busy', icon: 'assets/images/svg/status-svgs/busy.svg' },
    { id: 'Do_not_distrub', name: 'Do not distrub', cssClass: 'do_not_distrub', icon: 'assets/images/svg/status-svgs/dnd.svg' },
    { id: 'Away', name: 'Appear Away', cssClass: 'away', icon: 'assets/images/svg/status-svgs/away.svg' },
    { id: 'Offline', name: 'Appear Offline', cssClass: 'offline', icon: 'assets/images/svg/status-svgs/offline.svg' },
];

export const RED_COLOR_COMBO = {
    backgroundColor: '#ffd8e1',
    textColor: '#ed345a',
};

export const GREEN_COLOR_COMBO = {
    backgroundColor: '#84f5e3',
    textColor: '#19a78d',
};

export const YELLOW_COLOR_COMBO = {
    backgroundColor: '#f4e367',
    textColor: '#fd8925',
};

export const ORANGE_COLOR_COMBO = {
    backgroundColor: '#bcdff8',
    textColor: '#1996f2',
};
