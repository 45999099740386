import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@teacherapp_env/environment';

@Injectable({
  providedIn: 'root'
})
export class FilterAreaApiService {

  env = environment;
  baseUrl = this.env.base_url;

  constructor(
    private http: HttpClient
  ) { }
  
  fetchApplicableOrganizationInfo(params: any) {
    return this.http.get(`${this.baseUrl}/get_parent_data`, { params });
  }

  getParentInfoForCurrentSelection(params: any) {
    return this.http.post(`${this.baseUrl}/filters_data`, params);
  }

  getGlobalInfoForCurrentSelection(params: any) {
    return this.http.post(`${this.baseUrl}/old_filters_data`, params);
  }

  addFilterSettings(data: any) {
    return this.http.post(`${this.baseUrl}/org/add_filter`, data);
  }

  updateFilterSettings(data: any) {
    return this.http.put(`${this.baseUrl}/org/edit_filter`, data);
  }

  getSavedFilters(params: any) {
    return this.http.get(`${this.baseUrl}/org/get_filter`, { params });
  }

  deleteFilterSetting(filterId: any) {
    return this.http.delete(`${this.baseUrl}/org/remove_filter?id=${filterId}`);
  }

  addLastChangedFilterSettings(data: any) {
    return this.http.post(`${this.baseUrl}/org/add_latest_filter`, data);
  }

  getLastAppliedFilters(params: any) {
    return this.http.get(`${this.baseUrl}/org/get_latest_filter`, { params });
  }

  // /**
  //  * @deprecated
  //  */
  // getAllParentInfoOfSelectedItem(params: any) {
  //   return this.http.get(`${this.baseUrl}/get_parent_info`, { params });
  // }

  // /**
  //  * @deprecated
  //  */
  // getFilterSuggestions(params: any) {
  //   return this.http.post(`${this.baseUrl}/get_filter_data_by_org`, params);
  // }

  // /**
  //  * @deprecated
  //  */
  // getDataForOrganizatonTOCLassInTreeFormat(params: any) {
  //   return this.http.get(`${this.baseUrl}/get_child_mapping`, { params });
  // }
  
  // /**
  //  * @deprecated
  //  */
  // getDataForBranchTOSubjectInTreeFormatByOrgId(params: any) {
  //   return this.http.get(`${this.baseUrl}/get_child_data_by_org`, { params });
  // }

}
