import { Pipe, PipeTransform } from '@angular/core';
import { SUPER_ADMIN_ROLE_KEY } from '@teacherapp_core/common/constants';
import { TEAM_LEVEL_PAGE_MODULE_OPERATION_MAP } from '@teacherapp_core/common/permission-level.constant';
import { ITeam } from '@teacherapp_core/interfaces/organization-team.interface';
import { IPageModulePermission, IPageOperationSetting, IUserPermissionInfo } from '@teacherapp_core/interfaces/permission-setting.interface';
import { LocalService } from '@teacherapp_core/services/local.service';
import { PermissionHandlerService } from '@teacherapp_core/services/permission-handler.service';

@Pipe({
  name: 'checkStaffPermission'
})
export class CheckStaffPermissionPipe implements PipeTransform {

  currentUserData = null;
  currentActiveTeamConfig: ITeam = null;
  superAdminRoleValue = SUPER_ADMIN_ROLE_KEY;
  pageModuleOperationLevelMap = TEAM_LEVEL_PAGE_MODULE_OPERATION_MAP;

  constructor(
    private localService: LocalService,
    private permissionHandlerService?: PermissionHandlerService,
  ) {
    this.currentUserData = this.localService.getUserDataLocalStorage() ? this.localService.getUserDataLocalStorage().user : null;
  }

  /**
   * 
   * @param operationSetting 
   * @param currentStaffPermission 
   * @returns 
   */
  transform(operationSetting: IPageOperationSetting, currentStaffPermission: IUserPermissionInfo[], defaultPermission: IPageModulePermission = 'VIEW'): boolean {
    let operationAllowed = false;

    // IF DESIRED PERMISSION CHECK FOR 'global_organization' as super doerDo User
    if (operationSetting.permissionLevel === 'global_organization') {
      // IF STAFF ACCESS FLAG IS SET TO TRUE THEN PERMISSION CAN BE GRANTED
      if (this.currentUserData && this.currentUserData.staff_access) {
        return true;
      }
    }

    // IF DESIRED PERMISSION CHECK FOR 'specific_team' i.e individual team member, Or 'global_team' i.e Class App Super User
    if (operationSetting.permissionLevel === 'specific_team' || operationSetting.permissionLevel === 'global_team') {
      const _staff_role = this.localService.getOrganizationSpecificStaffRole(operationSetting.organizationId);
      // IF teach_app_role FIELD IS SET TO 'SuperAdmin' THEN PERMISSION CAN BE GRANTED
      if (_staff_role === this.superAdminRoleValue) {
        return true;
      }
    }

    if (!currentStaffPermission) {
      return operationAllowed;
    }

    if (this.permissionHandlerService) {
      this.currentActiveTeamConfig = this.permissionHandlerService.currentSelectedTeamConfig.value;
      // console.log('currentActiveTeamConfig', this.currentActiveTeamConfig);
    }

    
    // const filteredStaffPermission = currentStaffPermission.filter((_permission) => _permission.permissionLevel === operationSetting.permissionLevel);
    const filteredStaffPermission = currentStaffPermission.slice(0);
    for (const permission of filteredStaffPermission) {
      let _is_denied_permission = false;
      // CHECKING IF TEAM AUTH GROOUP LEVEL IS DENIED FOR PERTICULAR MODULE OPERATION
      if (this.currentActiveTeamConfig && this.currentActiveTeamConfig.auth_group_level) {
        if (this.pageModuleOperationLevelMap[permission.moduleName].indexOf(this.currentActiveTeamConfig.auth_group_level) === -1) {
          console.log('DENIED PERMISSION');
          _is_denied_permission = true;
          // IF DENIED THEN ONLYY VIEW PERMISSION CAN BE GRANTED
          if (operationSetting.permission === defaultPermission) {
            operationAllowed = true;
          }
          // OTHER PERMISSION CAN NOT BE GRANTED
          else {
            operationAllowed = false;
          }
          break;
        }
      }

      if (!_is_denied_permission) {
        // IF MODULE IS ALLOWED TO OPERATE THEN GO FOR NORMAL PERMISSION CHECK
        if (operationSetting.permission === defaultPermission) {
          operationAllowed = true;
          break;
        }
        if (permission.allowedPermission.indexOf(operationSetting.permission) >= 0) {
          operationAllowed = true;
          break;
        }
      }

      // if (operationSetting.permissionLevel === 'organization' && permission.organizationId === operationSetting.organizationId && permission.allowedPermission.indexOf(operationSetting.permission) >= 0) {
      //   operationAllowed = true;
      //   break;
      // }
      // else if (operationSetting.permissionLevel === 'branch' && permission.branchId === operationSetting.branchId && permission.allowedPermission.indexOf(operationSetting.permission) >= 0) {
      //   operationAllowed = true;
      //   break;
      // }
    }
    return operationAllowed;
  }

}
