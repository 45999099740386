import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { DataService } from '@teacherapp_core/services/data.service';
import { FilterAreaApiService } from '@teacherapp_core/services/filter-area-api.service';
import { LocalService } from '@teacherapp_core/services/local.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationListResolver implements Resolve<any> {

  userId = 0;

  constructor(
    private router: Router,
    private localService: LocalService,
    private dataService: DataService,
    private filterAreaApiService: FilterAreaApiService,
  ) { }
  
  createApiParams() {
    const params: any = new HttpParams({
      fromObject: {
        teacher: this.userId,
        page: 1
      }
    });
    return params;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.userId = this.localService.getUserIdLocalStorage();
    if (!this.userId) {
      return of(null);
    }

    const _is_org_suggestion_data_fetched = this.dataService.organizationSuggestionDataFetched.value;
    if (_is_org_suggestion_data_fetched) {
      return of(null);
    }
    // const _user_data = this.localService.getUserDataLocalStorage();
    // if (_user_data && _user_data.org_data && _user_data.org_data.length) {
    //   return of({ data: _user_data.org_data, error: null });
    // }

    // if(this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.skipCourseDetailResolver) {
    //   return of(null);
    // }

    let params = this.createApiParams();
    return this.filterAreaApiService.fetchApplicableOrganizationInfo(params).pipe(
      map((response: any) => ({ data: response, error: null })),
      catchError((error) => of({ data: [], error: error }))
    );
  }
}
