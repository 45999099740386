import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IUserOnlineStatus } from '@teacherapp_core/interfaces/channel-data.interface';
import { CheckVariableTypePipe } from '@teacherapp_core/pipes/check-variable-type/check-variable-type.pipe';
import { GetUserStatusDetailPipe } from '@teacherapp_core/pipes/get-user-status-detail/get-user-status-detail.pipe';
import { environment } from '@teacherapp_env/environment';

@Component({
  selector: 'app-user-avatar-pic-ui',
  templateUrl: './user-avatar-pic-ui.component.html',
  styleUrls: ['./user-avatar-pic-ui.component.scss']
})
export class UserAvatarPicUiComponent implements OnInit, OnChanges {

  @Input() userInfo: any = null;
  @Input() isPreviousMsgFromSameSender = false;
  @Input() userAvatarMode: string = 'MESSAGE_SENDER';
  @Input() panelClass = '';

  userDisplayName = '';
  userDisplayPic = '';
  userCurrentStatus: IUserOnlineStatus = null;

  env = environment;
  imagePath = this.env.cdn_urls.image;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes);
    if (changes['userInfo'] && changes['userInfo'].currentValue) {
      const _current_user_info = changes['userInfo'].currentValue;
      if (this.userAvatarMode === 'MESSAGE_SENDER') {
        const _is_obj_provided = new CheckVariableTypePipe().transform(_current_user_info)
        if (_is_obj_provided) {
          this.userDisplayName = _current_user_info.user_name;
          this.userDisplayPic = _current_user_info.profile_pic;
          this.userCurrentStatus = _current_user_info.member_status;
          if (this.userCurrentStatus) {
            this.userCurrentStatus.status_detail = new GetUserStatusDetailPipe().transform(this.userCurrentStatus.online_status);
          }
        }
      }
      else if (this.userAvatarMode === 'CHANNEL_MEMBER') {
        this.userDisplayName = _current_user_info.user_id.username;
        this.userDisplayPic = _current_user_info.profile_pic;
        this.userCurrentStatus = _current_user_info.member_status;
        if (this.userCurrentStatus) {
          this.userCurrentStatus.status_detail = new GetUserStatusDetailPipe().transform(this.userCurrentStatus.online_status);
        }
      }
      else if (this.userAvatarMode === 'USER_VIEW') {
        this.userDisplayName = _current_user_info.username;
        this.userDisplayPic = _current_user_info.profile_pic;
        this.userCurrentStatus = _current_user_info.member_status;
        if (this.userCurrentStatus) {
          this.userCurrentStatus.status_detail = new GetUserStatusDetailPipe().transform(this.userCurrentStatus.online_status);
        }
      }
      else if (this.userAvatarMode === 'USER_VIEW_2') {
        this.userDisplayName = _current_user_info.name;
        this.userDisplayPic = _current_user_info.avatar_link;
        this.userCurrentStatus = null;
        if (this.userCurrentStatus) {
          this.userCurrentStatus.status_detail = new GetUserStatusDetailPipe().transform(this.userCurrentStatus.online_status);
        }
      }
    }
  }

  ngOnInit(): void {
  }

}
