export const dynamicCSSFileSettings: any[] = [
    {
        name: '',
        description: '',
        fileurl: 'quiz-section.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/quiz-preview-styles/quiz-section.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'quiz-generator.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/quiz-generator-styles/quiz-generator.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'qf-preview.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/question-folder-styles/qf-preview.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'class-preview.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/class-window-styles/class-preview.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'message-panel-window.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/class-window-styles/message-panel-window.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'course-lv-container-wrapper.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/tution-courses-styles/course-lv-container-wrapper.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'supperbook-item.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/book-styles/supperbook-item.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'supperbook-item-upcoming-class.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/book-styles/supperbook-item-upcoming-class.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'upcoming-classes-inner.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/class-styles/upcoming-classes-inner.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'tag-list.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/live-courses/tag-list.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'course-item.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/live-courses/course-item.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'grade-data-menu.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/live-courses/grade-data-menu.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'list-data-view.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/topics-view/list-data-view.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'live-actn-tag.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/tag-styles/live-actn-tag.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'messaging-view.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/messaging-styles/messaging-view.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'message-action-bs.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/bottom-sheet-styles/message-action-bs.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'result-data-container.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/assignment-styles/result-data-container.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'emoji-mart-global.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/emoji-mart-styles/emoji-mart-global.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'accordion-style-1.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/expansion-panel-styles/accordion-style-1.scss',
    },
    {
        name: '',
        description: '',
        fileurl: 'class-group-badge.css',
        actualFileUrl: 'projects/teacher-app/src/assets/scss/assignment-styles/class-group-badge.scss',
    },
];