<!-- <ng-container *ngIf="(messageInfo.student_detail); else teacherImageView">
    <img *ngIf="messageInfo.student_detail.avatar_link" [src]="imagePath + messageInfo.student_detail.avatar_link" alt="" class="profile-pic" [class.hide]="isPreviousMsgFromSameSender">
    <span *ngIf="!messageInfo.student_detail.avatar_link" alt="" class="profile-pic" [style.backgroundColor]="messageInfo.student_detail?.colorCode" [class.hide]="isPreviousMsgFromSameSender">{{ messageInfo.student_detail.student_name ? messageInfo.student_detail.student_name.slice(0,2) : '' }}</span>
</ng-container>
<ng-template #teacherImageView>
    <ng-container *ngIf="(messageInfo.staff_detail); else noUserImageView">
        <img *ngIf="messageInfo.staff_detail.avatar_link" [src]="imagePath + messageInfo.staff_detail.avatar_link" alt="" class="profile-pic" [class.hide]="isPreviousMsgFromSameSender">
        <span *ngIf="!messageInfo.staff_detail.avatar_link" alt="" class="profile-pic" [style.backgroundColor]="messageInfo.staff_detail?.colorCode" [class.hide]="isPreviousMsgFromSameSender">{{ messageInfo.staff_detail.staff_name ? messageInfo.staff_detail.staff_name.slice(0,2) : '' }}</span>
    </ng-container>
</ng-template>
<ng-template #noUserImageView>
    <img [src]="'assets/images/user.png'" alt="" class="profile-pic">
</ng-template> -->

<!-- <img [src]="'https://content.doerdo.com/1652079748281_student_profile_pic.png'" alt="" class="profile-pic" [class.hide]="isPreviousMsgFromSameSender"> -->
<!-- <ng-container *ngIf="(messageInfo.member_id && (messageInfo.member_id | checkVariableType)) ; else noUserImageView">
    <ng-container *ngIf="messageInfo.member_id.profile_pic; else memberInitialNameView">
        <img [src]="imagePath + messageInfo.member_id.profile_pic" alt="" class="profile-pic" [ngClass]="(messageInfo.member_status && messageInfo.member_status.online_status) ? (messageInfo.member_status.online_status | lowercase) : ''">
    </ng-container>
    <ng-template #memberInitialNameView>
        <ng-container *ngIf="messageInfo.member_id.user_name; else noUserImageView">
            <span class="profile-pic" [style.backgroundColor]="{profile_name: messageInfo.member_id.user_name } | getRandomColorCode : 'NAME_SPECIFIC'" [ngClass]="(messageInfo.member_status && messageInfo.member_status.online_status) ? (messageInfo.member_status.online_status | lowercase) : ''">{{ messageInfo.member_id.user_name | getNameInitial }}</span>
        </ng-container>
    </ng-template>
</ng-container>
<ng-template #noUserImageView>
    <img [src]="'assets/images/user.png'" alt="" class="profile-pic" [class.hide]="isPreviousMsgFromSameSender">
</ng-template> -->
<!-- [matTooltipHideDelay]="50000" -->
<ng-container *ngIf="(userDisplayName || userDisplayPic) ; else noUserImageView">
    <ng-container *ngIf="userDisplayPic; else memberInitialNameView">
        <span class="photo mr-2" [ngClass]="(userCurrentStatus && userCurrentStatus.online_status) ? (userCurrentStatus.status_detail.cssClass + ' ' + panelClass) : panelClass">
            <img [src]="imagePath + userDisplayPic" alt="">
            <span class="member--status" *ngIf="userCurrentStatus && userCurrentStatus.status_detail" matTooltipClass="fe-btn-tooltip" [matTooltip]="userCurrentStatus.status_detail.name" matTooltipPosition="above"><img [src]="userCurrentStatus.status_detail.icon"></span>
        </span>
    </ng-container>
    <ng-template #memberInitialNameView>
        <ng-container *ngIf="userDisplayName; else noUserImageView">
            <span class="photo mr-2" [style.backgroundColor]="{profile_name: userDisplayName } | getRandomColorCode : 'NAME_SPECIFIC'" [ngClass]="(userCurrentStatus && userCurrentStatus.status_detail) ? (userCurrentStatus.status_detail.cssClass + ' ' + panelClass) : panelClass">
                {{ userDisplayName | getNameInitial }}
                <span class="member--status" *ngIf="userCurrentStatus && userCurrentStatus.status_detail" matTooltipClass="fe-btn-tooltip" [matTooltip]="userCurrentStatus.status_detail.name" matTooltipPosition="above"><img [src]="userCurrentStatus.status_detail.icon"></span>
            </span>
        </ng-container>
    </ng-template>
</ng-container>
<ng-template #noUserImageView>
    <img [src]="'assets/images/user.png'" alt="" class="photo mr-2" [class.hide]="isPreviousMsgFromSameSender">
</ng-template>