<div class="mainNavContainer" style="display:none;">
  <div class="sidemenu-logo" style="display: none;">
    <a href="javascript:void(0);">
      <img src="./assets/images/icons/doerdo-logo-left-panel.png" alt="">
    </a>
  </div>
  <div class="mainNavScrollArea">
    <ng-scrollbar>
      <div class="sideMenu_Area out">
        <div class="logoBox d-none">
          <div class="menu_toggle">
            <i class="fa fa-times " aria-hidden="true"></i>
          </div>
        </div>
        <ul class="sideMenu">
          <li [routerLink]="['/teaching-management']" routerLinkActive="active" matTooltip="Live Classes" matTooltipPosition="right">
            <a href="javascript:void(0)">
              <img class="menuImg " src="../../../../assets/images/icons/live-class.svg">
              <span class="txt">Live Classes</span>
            </a>
          </li>
          <!-- <li [routerLink]="['/home']" routerLinkActive="active" matTooltip="Home" matTooltipPosition="right">
            <a href="javascript:void(0);">
              <img class="menuImg md" src="../../../../assets/images/icons/mock-exam-icon.svg">
              <span class="txt">Home <div id="mock-exam-badge"></div></span>
            </a>
          </li>
          <li class="foot" matTooltip="Support" matTooltipPosition="right">
            <a href="javascript:void(0);">
              <img class="menuImg " src="../../../../assets/images/icons/assessment-support.svg">
              <span class="txt">Support</span>
            </a>
          </li> -->
          <!-- <li matTooltip="Settings" matTooltipPosition="right">
            <a href="javascript:void(0)">
              <img class="menuImg " src="../../../../assets/images/icons/account-icon.svg">
              <span class="txt">Settings</span>
            </a>
          </li> -->
          <li class="logout-li-button foot" matTooltip="Logout" matTooltipPosition="right" (click)="doLogout()">
            <a href="javascript:void(0)">
              <img class="menuImg " src="../../../../assets/images/icons/logout-icon.svg">
              <span class="txt">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </ng-scrollbar>
  </div>
</div>

<!-- app-nav-collapse -->
<div class="app-nav-container">
  <span class="nav-collapse-arrow" (click)="toggleLeftNavMenu($event)">
    <span class="arrow"></span>
  </span>
  <div class="app-nav-head" *ngIf="currentPageFocusLevel">
    <span class="nav-level">
      <span>Focus Level:</span> 
      <strong>
        <img [src]="currentPageFocusLevel.icon" alt=""/> {{ currentPageFocusLevel.name }}
      </strong>
    </span>
  </div>
  <div class="app-nav-area">
    <ng-scrollbar>
      <ul class="app-main-nav app-main-nav-new">
        <!-- <li>
          <a href="javascript:void(0);" [routerLink]="'/assignment-design'">
            <img src="assets/images/svg/overview-icon.svg" alt=""> 
            Overview
          </a>
        </li> -->
        <!-- NAV 2 START -->
        <li class="drop-menu-item-set sm-view" #elem1 [class.active]="(rla1.isActive || rla2.isActive || rla3.isActive || rla4.isActive || rla5.isActive || rla6.isActive || rla17.isActive || rla18.isActive || rla19.isActive || rla191.isActive)" [class.show-menu]="(checkClassExists(elem1, 'show-menu') || (rla1.isActive || rla2.isActive || rla3.isActive || rla4.isActive || rla5.isActive || rla6.isActive || rla17.isActive || rla18.isActive || rla19.isActive || rla191.isActive))">
          <button class="nav-a" [matMenuTriggerFor]="navmenu2" >
            <img src="./assets/images/svg/hierarchy-icon.svg" alt=""> 
            Academic Hierarchy
            <span class="drop-arrow"></span>
          </button>
          <mat-menu #navmenu2="matMenu" class="nav-mat-menu">
            <ul class="app-sub-nav">
              <li [routerLinkActive]="'active'" #rla1="routerLinkActive">
                <button mat-menu-item class="nav-a" [routerLink]="'/teaching-management/organization'" [queryParamsHandling]="'preserve'" >Organization</button>
              </li>
              <li [routerLinkActive]="'active'" #rla191="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="'/teaching-management/organization-team'" [queryParamsHandling]="'preserve'">Teams</a>
              </li>
              <li [routerLinkActive]="'active'" #rla2="routerLinkActive">
                <button mat-menu-item class="nav-a" [routerLink]="'/teaching-management/branch'" [queryParamsHandling]="'preserve'">Branch</button>
              </li>
              <li [routerLinkActive]="'active'" #rla3="routerLinkActive">
                <button mat-menu-item class="nav-a" [routerLink]="'/teaching-management/courses'" [queryParamsHandling]="'preserve'">Courses</button>
              </li>
              <li [routerLinkActive]="'active'" #rla4="routerLinkActive">
                <button mat-menu-item class="nav-a" [routerLink]="'/teaching-management/batch'" [queryParamsHandling]="'preserve'">Batch</button>
              </li>
              <li [routerLinkActive]="'active'" #rla5="routerLinkActive">
                <button mat-menu-item class="nav-a" [routerLink]="'/teaching-management/subject'" [queryParamsHandling]="'preserve'">Batch Subjects</button>
              </li>
              <li [routerLinkActive]="'active'" #rla6="routerLinkActive">
                <button mat-menu-item class="nav-a" [routerLink]="'/teaching-management/classes'" [queryParamsHandling]="'preserve'">Classes</button>
              </li>
              <li [routerLinkActive]="'active'" #rla17="routerLinkActive">
                <button mat-menu-item class="nav-a" [routerLink]="'/teaching-management/student'" [queryParamsHandling]="'preserve'">Students</button>
              </li>
              <li [routerLinkActive]="'active'" #rla18="routerLinkActive">
                <button mat-menu-item class="nav-a" [routerLink]="'/teaching-management/organization-grade'" [queryParamsHandling]="'preserve'">Grades</button>
              </li>
              <li [routerLinkActive]="'active'" #rla19="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="'/teaching-management/organization-subject'" [queryParamsHandling]="'preserve'">Grade Subjects</a>
              </li>
            </ul>
          </mat-menu>
        </li>

        <li class="drop-menu-item-set" #elem1 [class.active]="(rla1.isActive || rla2.isActive || rla3.isActive || rla4.isActive || rla5.isActive || rla6.isActive || rla17.isActive || rla18.isActive || rla19.isActive || rla191.isActive)" [class.show-menu]="(checkClassExists(elem1, 'show-menu') || (rla1.isActive || rla2.isActive || rla3.isActive || rla4.isActive || rla5.isActive || rla6.isActive || rla17.isActive || rla18.isActive || rla19.isActive || rla191.isActive))">
          <a href="javascript:void(0);" (click)="toggleDropMenu($event)">
            <img src="assets/images/svg/hierarchy-icon.svg" alt=""> 
            Academic Hierarchy
            <span class="drop-arrow"></span>
          </a>
          <ul class="app-sub-nav">
            <li [routerLinkActive]="'active'" #rla1="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/organization'" [queryParamsHandling]="'preserve'" >Organization</a>
            </li>
            <li [routerLinkActive]="'active'" #rla191="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/organization-team'" [queryParamsHandling]="'preserve'">Teams</a>
            </li>
            <li [routerLinkActive]="'active'" #rla2="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/branch'" [queryParamsHandling]="'preserve'">Branch</a>
            </li>
            <li [routerLinkActive]="'active'" #rla3="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/courses'" [queryParamsHandling]="'preserve'">Courses</a>
            </li>
            <li [routerLinkActive]="'active'" #rla4="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/batch'" [queryParamsHandling]="'preserve'">Batch</a>
            </li>
            <li [routerLinkActive]="'active'" #rla5="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/subject'" [queryParamsHandling]="'preserve'">Batch Subjects</a>
            </li>
            <li [routerLinkActive]="'active'" #rla6="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/classes'" [queryParamsHandling]="'preserve'">Classes</a>
            </li>
            <li [routerLinkActive]="'active'" #rla17="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/student'" [queryParamsHandling]="'preserve'">Students</a>
            </li>
            <li [routerLinkActive]="'active'" #rla18="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/organization-grade'" [queryParamsHandling]="'preserve'">Grades</a>
            </li>
            <li [routerLinkActive]="'active'" #rla19="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/organization-subject'" [queryParamsHandling]="'preserve'">Grade Subjects</a>
            </li>
            <!-- <li>
              <a href="javascript:void(0);">Teachers</a>
            </li>
            <li>
              <a href="javascript:void(0);">Students</a>
            </li> -->
          </ul>
        </li>
        <!-- NAV 2 END -->

        <!-- NAV 3 START -->
        <li class="drop-menu-item-set sm-view" #elem2 [class.active]="(rla7.isActive || rla8.isActive || rla9.isActive)" [class.show-menu]="(checkClassExists(elem2, 'show-menu') || (rla7.isActive || rla8.isActive || rla9.isActive))">
          <button class="nav-a" [matMenuTriggerFor]="navmenu3" >
            <img src="assets/images/svg/course-focus-icon.svg" alt=""> 
            Course Focus
            <span class="drop-arrow"></span>
          </button>
          <mat-menu #navmenu3="matMenu" class="nav-mat-menu">
            <ul class="app-sub-nav">
              <li [routerLinkActive]="'active'" #rla7="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="'/teaching-management/course-detail'" [queryParamsHandling]="'preserve'">Course details</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Teachers</a>
              </li>
              <li [routerLinkActive]="'active'" #rla8="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="'/teaching-management/course-course-path'" [queryParamsHandling]="'preserve'">Course Path</a>
              </li>
              <li [routerLinkActive]="'active'" #rla9="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="'/teaching-management/course-course-book'" [queryParamsHandling]="'preserve'">Live Course Book</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Classes</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Schedule</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Students</a>
              </li>
            </ul>
          </mat-menu>
        </li>

        <li class="drop-menu-item-set" #elem2 [class.active]="(rla7.isActive || rla8.isActive || rla9.isActive)" [class.show-menu]="(checkClassExists(elem2, 'show-menu') || (rla7.isActive || rla8.isActive || rla9.isActive))">
          <a href="javascript:void(0);" (click)="toggleDropMenu($event)">
            <img src="assets/images/svg/course-focus-icon.svg" alt=""> 
            Course Focus
            <span class="drop-arrow"></span>
          </a>
          <ul class="app-sub-nav">
            <li [routerLinkActive]="'active'" #rla7="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/course-detail'" [queryParamsHandling]="'preserve'">Course details</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Teachers</a>
            </li>
            <li [routerLinkActive]="'active'" #rla8="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/course-course-path'" [queryParamsHandling]="'preserve'">Course Path</a>
            </li>
            <li [routerLinkActive]="'active'" #rla9="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/course-course-book'" [queryParamsHandling]="'preserve'">Live Course Book</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Classes</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Schedule</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Students</a>
            </li>
          </ul>
        </li>        
        <!-- NAV 3 END -->
        
        <!-- NAV 4 START -->
        <li class="drop-menu-item-set sm-view" #elem3 [class.active]="(rla33.isActive)" [class.show-menu]="(checkClassExists(elem3, 'show-menu') || (rla33.isActive))">
          <button class="nav-a" [matMenuTriggerFor]="navmenu4" >
            <img src="assets/images/svg/tasks-icon.svg" alt="">
            Tasks
            <span class="drop-arrow"></span>
          </button>
          <mat-menu #navmenu4="matMenu" class="nav-mat-menu">
            <ul class="app-sub-nav">
              <li class="disabled">
                <a href="javascript:void(0);">To Do's</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Class Tests</a>
              </li>
              <li [routerLinkActive]="'active'" #rla33="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="'/teaching-management/assignment'" [queryParamsHandling]="'preserve'">Assignments</a>
              </li>
            </ul>
          </mat-menu>
        </li>
        <li class="drop-menu-item-set" #elem3 [class.active]="(rla33.isActive)" [class.show-menu]="(checkClassExists(elem3, 'show-menu') || (rla33.isActive))">
          <a href="javascript:void(0);" (click)="toggleDropMenu($event)">
            <img src="assets/images/svg/tasks-icon.svg" alt="">
            Tasks
            <span class="drop-arrow"></span>
          </a>
          <ul class="app-sub-nav">
            <li class="disabled">
              <a href="javascript:void(0);">To Do's</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Class Tests</a>
            </li>
            <li [routerLinkActive]="'active'" #rla33="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/assignment'" [queryParamsHandling]="'preserve'">Assignments</a>
            </li>
          </ul>
        </li>
        <!-- NAV 4 END -->

        <!-- NAV 5 START -->        
        <li class="drop-menu-item-set sm-view" #elem4 [class.active]="(rla41.isActive)" [class.show-menu]="(checkClassExists(elem4, 'show-menu') || (rla41.isActive))">
          <button class="nav-a" [matMenuTriggerFor]="navmenu5" >
            <img src="assets/images/svg/calendar-icon.svg" alt=""> 
            Calendar
            <span class="drop-arrow"></span>
          </button>
          <mat-menu #navmenu5="matMenu" class="nav-mat-menu">
            <ul class="app-sub-nav">
              <li [routerLinkActive]="'active'" #rla41="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="'/teaching-management/calendar'" [queryParamsHandling]="'preserve'">Schdedule</a>
              </li>
            </ul>
          </mat-menu>
        </li>
        <li class="drop-menu-item-set" #elem4 [class.active]="(rla41.isActive)" [class.show-menu]="(checkClassExists(elem4, 'show-menu') || (rla41.isActive))">
          <a href="javascript:void(0);" (click)="toggleDropMenu($event)">
            <img src="assets/images/svg/calendar-icon.svg" alt=""> 
            Calendar
            <span class="drop-arrow"></span>
          </a>
          <ul class="app-sub-nav">
            <li [routerLinkActive]="'active'" #rla41="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/calendar'" [queryParamsHandling]="'preserve'">Schdedule</a>
            </li>
          </ul>
        </li>
        <!-- NAV 5 END -->

        <!-- NAV 6 START -->     
        <li class="drop-menu-item-set sm-view disabled">
          <button class="nav-a" [matMenuTriggerFor]="navmenu6" >
            <img src="assets/images/svg/doer-drive-icon.svg" alt=""> 
            Doer Drive
            <span class="drop-arrow"></span>
          </button>
          <mat-menu #navmenu6="matMenu" class="nav-mat-menu">
            <ul class="app-sub-nav">
              <li>
                <a href="javascript:void(0);">Folders</a>
              </li>
              <li>
                <a href="javascript:void(0);">Notes</a>
              </li>
              <li>
                <a href="javascript:void(0);">Docs</a>
              </li>
              <li>
                <a href="javascript:void(0);">MindMaps</a>
              </li>
            </ul>
          </mat-menu>
        </li>
        <li class="drop-menu-item-set disabled">
          <a href="javascript:void(0);">
            <img src="assets/images/svg/doer-drive-icon.svg" alt=""> 
            Doer Drive
            <span class="drop-arrow"></span>
          </a>
          <ul class="app-sub-nav">
            <li>
              <a href="javascript:void(0);">Folders</a>
            </li>
            <li>
              <a href="javascript:void(0);">Notes</a>
            </li>
            <li>
              <a href="javascript:void(0);">Docs</a>
            </li>
            <li>
              <a href="javascript:void(0);">MindMaps</a>
            </li>
          </ul>
        </li>
        <!-- NAV 6 END -->

        <!-- NAV 7 START -->   
        <li class="drop-menu-item-set sm-view" #elem6 [class.active]="(rla64.isActive)" [class.show-menu]="(checkClassExists(elem6, 'show-menu') || (rla64.isActive))">
          <button class="nav-a" [matMenuTriggerFor]="navmenu7" >
            <img src="assets/images/svg/message-icon.svg" alt=""> 
            Messages
            <span class="drop-arrow"></span>
          </button>
          <mat-menu #navmenu7="matMenu" class="nav-mat-menu">
            <ul class="app-sub-nav">
              <li class="disabled">
                <a href="javascript:void(0);">OneBox</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Inbox</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Announcements</a>
              </li>
              <li [routerLinkActive]="'active'" #rla64="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="['/channel-management/message-box']" [queryParamsHandling]="'preserve'">Chats</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Threads</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Doubts</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Issue Reporting</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Feedback</a>
              </li>
            </ul>
          </mat-menu>
        </li>
        <li class="drop-menu-item-set" #elem6 [class.active]="(rla64.isActive)" [class.show-menu]="(checkClassExists(elem6, 'show-menu') || (rla64.isActive))">
          <a href="javascript:void(0);" (click)="toggleDropMenu($event)">
            <img src="assets/images/svg/message-icon.svg" alt=""> 
            Messages
            <span class="drop-arrow"></span>
          </a>
          <ul class="app-sub-nav">
            <li class="disabled">
              <a href="javascript:void(0);">OneBox</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Inbox</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Announcements</a>
            </li>
            <li [routerLinkActive]="'active'" #rla64="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="['/channel-management/message-box']" [queryParamsHandling]="'preserve'">Chats</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Threads</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Doubts</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Issue Reporting</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Feedback</a>
            </li>
          </ul>
        </li>
        <!-- NAV 7 END -->

        <!-- NAV 8 START -->   
        <li class="drop-menu-item-set sm-view" #elem7 [class.active]="(rla71.isActive || rla72.isActive || rla73.isActive || rla74.isActive)" [class.show-menu]="(checkClassExists(elem7, 'show-menu') || (rla71.isActive || rla72.isActive || rla73.isActive || rla74.isActive))">
          <button class="nav-a" [matMenuTriggerFor]="navmenu8" >
            <img src="assets/images/svg/book-gray-icon.svg" alt=""> 
            Library
            <span class="drop-arrow"></span>
          </button>
          <mat-menu #navmenu8="matMenu" class="nav-mat-menu">
            <ul class="app-sub-nav">
              <li [routerLinkActive]="'active'" #rla71="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="'/teaching-management/library-course-path'" [queryParamsHandling]="'preserve'">Course Path</a>
              </li>
              <li [routerLinkActive]="'active'" #rla72="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="'/teaching-management/library-course-book'" [queryParamsHandling]="'preserve'">Live Course Book</a>
              </li>
              <li [routerLinkActive]="'active'" #rla73="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="'/teaching-management/library-question-folders'" [queryParamsHandling]="'preserve'">Question Bank</a>
              </li>
              <li [routerLinkActive]="'active'" #rla74="routerLinkActive">
                <a href="javascript:void(0);" [routerLink]="'/teaching-management/library-question-folder-generator'" [queryParamsHandling]="'preserve'">Question Generator</a>
              </li>
              <li class="disabled">
                <a href="javascript:void(0);">Bookmarks</a>
              </li>
            </ul>
          </mat-menu>
        </li>
        <li class="drop-menu-item-set" #elem7 [class.active]="(rla71.isActive || rla72.isActive || rla73.isActive || rla74.isActive)" [class.show-menu]="(checkClassExists(elem7, 'show-menu') || (rla71.isActive || rla72.isActive || rla73.isActive || rla74.isActive))">
          <a href="javascript:void(0);" (click)="toggleDropMenu($event)">
            <img src="assets/images/svg/book-gray-icon.svg" alt=""> 
            Library
            <span class="drop-arrow"></span>
          </a>
          <ul class="app-sub-nav">
            <li [routerLinkActive]="'active'" #rla71="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/library-course-path'" [queryParamsHandling]="'preserve'">Course Path</a>
            </li>
            <li [routerLinkActive]="'active'" #rla72="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/library-course-book'" [queryParamsHandling]="'preserve'">Live Course Book</a>
            </li>
            <li [routerLinkActive]="'active'" #rla73="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/library-question-folders'" [queryParamsHandling]="'preserve'">Question Bank</a>
            </li>
            <li [routerLinkActive]="'active'" #rla74="routerLinkActive">
              <a href="javascript:void(0);" [routerLink]="'/teaching-management/library-question-folder-generator'" [queryParamsHandling]="'preserve'">Question Generator</a>
            </li>
            <li class="disabled">
              <a href="javascript:void(0);">Bookmarks</a>
            </li>
            <li>
              <a href="javascript:void(0);">Content library</a>
            </li>
          </ul>
        </li>
        <!-- NAV 8 END -->

        <!-- NAV 9 START --> 
        <li class="drop-menu-item-set sm-view disabled">
          <button class="nav-a" [matMenuTriggerFor]="navmenu9" >
            <img src="assets/images/svg/finance-icon.svg" alt="">
            Finance
            <span class="drop-arrow"></span>
          </button>
          <mat-menu #navmenu9="matMenu" class="nav-mat-menu">
            <ul class="app-sub-nav">
              <li>
                <a href="javascript:void(0);">Insights</a>
              </li>
              <li>
                <a href="javascript:void(0);">Teachers Pay</a>
              </li>
              <li>
                <a href="javascript:void(0);">Student Fees</a>
              </li>
            </ul>
          </mat-menu>
        </li>
        <li class="drop-menu-item-set disabled">
          <a href="javascript:void(0);">
            <img src="assets/images/svg/finance-icon.svg" alt="">
            Finance
            <span class="drop-arrow"></span>
          </a>
          <ul class="app-sub-nav">
            <li>
              <a href="javascript:void(0);">Insights</a>
            </li>
            <li>
              <a href="javascript:void(0);">Teachers Pay</a>
            </li>
            <li>
              <a href="javascript:void(0);">Student Fees</a>
            </li>
          </ul>
        </li>
      </ul>
    </ng-scrollbar>
  </div>
  <ul class="app-main-nav p-t">    
    <li class="disabled">
      <a href="javascript:void(0);">
        <img src="assets/images/svg/assessment-support.svg" alt=""> Support
      </a>
    </li>
    <li class="disabled">
      <a href="javascript:void(0);">
        <img src="assets/images/svg/account-icon.svg" alt=""> Settings
      </a>
    </li>
    <li>
      <button matTooltip="Logout" matTooltipPosition="right" (click)="doLogout()" class="nav-a">
        <img src="assets/images/svg/logout-icon.svg" alt=""> Logout
      </button>
    </li>
  </ul>
</div>