import { HttpErrorResponse } from "@angular/common/http";
import { FormControl, FormGroup, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { ILiveClass, IWhiteboardConfig, IWhiteBoardInfo } from "@teacherapp_core/interfaces/live-class.interface";
import { CHANNEL_MEMBER_ROLE_EXCEPT_LEADER_OPTIONS, DEFAULT_ERROR_MESSAGE_API_ERROR, DEFAULT_ERROR_MESSAGE_CORS_ERROR } from "./constants";
import { CalendarView } from "angular-calendar";

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

export class CustomFieldErrorMatcher implements ErrorStateMatcher {
    constructor(private customControl: FormControl) {}
  
    isErrorState(control: FormControl): boolean {
      return this.customControl && this.customControl.invalid && (this.customControl.dirty || this.customControl.touched);
    }
  }

export const markAllFormContrlAsDirty = (fg: FormGroup) => {
    Object.keys(fg.controls).forEach((key) => {
        fg.get(key)?.markAsDirty();
    });
}

const defaultErrorMessageHandler = (message: string, defaultMessage = DEFAULT_ERROR_MESSAGE_API_ERROR) => {
  if (message) {
    return message;
  }
  return defaultMessage;
}

export const commonErrorHandler = (errorInfo: any) => {
  if (errorInfo instanceof HttpErrorResponse) {
    const errorData = errorInfo.error;
    if (errorInfo.status === 0) {
      return defaultErrorMessageHandler(errorInfo.message, DEFAULT_ERROR_MESSAGE_CORS_ERROR);
    }
    else if (!errorData) {
      return defaultErrorMessageHandler(errorInfo.message);
    }
    // it will helpful when any server error coming for any exception
    else if (typeof errorData === 'string') {
      return errorData;
    }
    // if backend return error as object value
    else if (typeof errorData === 'object') {
      // then get possible keys in error
      const errKeys = Object.keys(errorData);
      if (errKeys.length) {
        // check if first error key is an array or not
        if (Array.isArray(errorData[errKeys[0]])) {
          // if array then return first message of array object
          return `${ errKeys[0]}: ${errorData[errKeys[0]][0]}`;
        }
        // if not array then return first object string value
        return errorData[errKeys[0]];
      }
    }
    else {
      return defaultErrorMessageHandler(errorInfo.message);
    }
  } 
  return defaultErrorMessageHandler('');
}

export const getColorCodeFromInitials = (profile_name: string) => {
  // Generate a hash code from the initials
  const hashCode = profile_name.split('').reduce((acc, char) => (char.charCodeAt(0) + ((acc << 5) - acc) * 16), 0);
  // Convert the hash code to a color code
  const color = '#' + (hashCode & 0x00FFFFFF).toString(16).toUpperCase().padEnd(6, '0').slice(0, 6);
  return color;
}

export const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const generateRandomColorWithContrast = (minValue = 100) => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  let component;
  for (let i = 0; i < 3; i++) {
    component = Math.floor(Math.random() * (256 - minValue) + minValue).toString(16);
    color += (component.length === 1) ? '0' + component : component;
  }
  return color;
}

export const getFirstDateOfWeek = (date: Date) => {
  const _currentDate = new Date(date);
  const _firstDay = new Date(_currentDate.setDate(_currentDate.getDate() - _currentDate.getDay())); /// FIRST DATE OF SELECTED MONTH
  return _firstDay;
}

export const getLastDateOfWeek = (date: Date) => {
  const _currentDate = new Date(date);
  const _lastDay = new Date(_currentDate.setDate(_currentDate.getDate() + (6 - _currentDate.getDay()))); /// FIRST DATE OF SELECTED MONTH
  return _lastDay;
}

export const getFirstDateOfMonth = (date: Date) => {
  const _currentDate = new Date(date);
  const _firstDay = new Date(_currentDate.setDate(1)); /// FIRST DATE OF SELECTED MONTH
  return _firstDay;
}

export const getFirstDateOfMonthWithSomeDayBefore = (date: Date, dayBefore = 1) => {
  const _currentDate = new Date(date);
  const _firstDay = new Date(_currentDate.setDate(dayBefore)); /// FIRST DATE OF SELECTED MONTH SOME BEFORE DATS
  return _firstDay;
}

export const getLastDateOfMonth = (date: Date) => {
  const _currentDate = new Date(date);
  const _lastDay = new Date(_currentDate.getFullYear(), _currentDate.getMonth() + 1, 0); /// LAST DATE OF SELECTED MONTH
  return _lastDay;
}

export const getLastDateOfMonthWithSomeDayAfter = (date: Date, dayAfter = 0) => {
  const _currentDate = new Date(date);
  const _lastDay = new Date(_currentDate.getFullYear(), _currentDate.getMonth() + 1, dayAfter); /// LAST DATE OF SELECTED MONTH WITH SOME FUTURE DAYS
  return _lastDay;
}

export const getBeginingOFDay = (date: Date) => {
  const _currentDate = new Date(date);
  const _startofDay = new Date(_currentDate.getFullYear(), _currentDate.getMonth(), _currentDate.getDate(), 0, 0, 0, 0); /// LAST DATE OF SELECTED MONTH
  return _startofDay;
}

export const getCalendarStartDateBasedOnCalendarViewMode = (date: Date, _current_view: CalendarView) => {
  let _start_date = date;
  if (_current_view === CalendarView.Month) {
    _start_date = getFirstDateOfMonthWithSomeDayBefore(date, -7);
  }
  else if (_current_view === CalendarView.Week) {
    _start_date = getFirstDateOfWeek(date);
  }
  return _start_date;
}

export const getCalendarEndDateBasedOnCalendarViewMode = (date: Date, _current_view: CalendarView) => {
  let _end_date = date;
  if (_current_view === CalendarView.Month) {
    _end_date = getLastDateOfMonthWithSomeDayAfter(date, 7);
  }
  else if (_current_view === CalendarView.Week) {
    _end_date = getLastDateOfWeek(date);
  }
  return _end_date;
}

export const checkIsNumber = (textValue: any) => {
  if (isNaN(textValue)) {
    return false;
  }
  return true;
}

export const isGivenValueEmpty = (textValue: any) => {
  if (textValue && textValue.trim()) {
    if (textValue !== '') {
      return false;
    }
  }
  return true;
}

export const getCurrentUserId = (currentUser: any) => {
  let id: number = 0;
  if (currentUser) {
    id = currentUser.id;
  }
  return id;
}

export const getOwnerOfWhiteboard = (_whiteBoardInfo: IWhiteboardConfig) => {
  return _whiteBoardInfo.created_by_teacher ? { id: _whiteBoardInfo.created_by_teacher.id, user_type: 'teacher' } : _whiteBoardInfo.created_by_student ? { id: _whiteBoardInfo.created_by_student.id, user_type: 'student' } : null;
}

export const checkOwnerOfWhiteboardAsMe = (_whiteBoardInfo: IWhiteboardConfig, userInfo: any) => {
  return _whiteBoardInfo.created_by_teacher && (_whiteBoardInfo.created_by_teacher.id === userInfo.id) ? true : false;
}

export const checkIsWhiteboardCreatedByTeacher = (_whiteBoardInfo: IWhiteboardConfig) => {
  return _whiteBoardInfo.created_by_teacher ? true : false;
}

export const checkValidWhiteboard = (_whiteboardparticipients: any, userInfo: any) =>{
  const _participientinfo = _whiteboardparticipients.find((eachParticipient) => (eachParticipient.user_student_id.id === userInfo.id && eachParticipient.user_type === userInfo.user_type));
  return _participientinfo ? true : false;
}

// export const checkIsWhiteboardWriteMode = (_whiteboard: IWhiteboardConfig,_whiteboardparticipients: IWhiteParticipient[], userInfo: any) => {
//   if (_whiteboard.is_global && userInfo.classHost) {
//     return true;
//   }
//   else if ((_whiteboard.created_by_teacher && _whiteboard.created_by_teacher.id) === userInfo.id) {
//     return true;
//   }
//   else {
//     const _participientinfo = _whiteboardparticipients.find((eachParticipient) => (eachParticipient.user_student_id.id === userInfo.id && eachParticipient.user_type === userInfo.user_type));
//     return _participientinfo ? _participientinfo.is_write : false;
//   }
// }

// export const checkIsWhiteboardAdminMode = (_whiteboard: IWhiteboardConfig, _whiteboardparticipients: IWhiteParticipient[], userInfo: any) => {
//   if (_whiteboard.is_global && userInfo.classHost) {
//     return true;
//   }
//   else if ((_whiteboard.created_by_teacher && _whiteboard.created_by_teacher.id) === userInfo.id) {
//     return true;
//   }
//   else {
//     const _participientinfo = _whiteboardparticipients.find((eachParticipient) => (eachParticipient.user_student_id.id === userInfo.id && eachParticipient.user_type === userInfo.user_type));
//     return _participientinfo ? _participientinfo.is_admin : false;
//   }
// }

export const formatWhiteboardParticipant = (_whiteboard: IWhiteboardConfig, _classdetails: ILiveClass) => {
  if (!_whiteboard.participants) {
    return;
  }
  _whiteboard.participants.forEach((eachWhiteParticipant) => {
    const mappedClassParticipant = _classdetails.allParticipients.find((eachClassParti) => eachClassParti.user_student_id === eachWhiteParticipant.user_student_id.id && eachClassParti.user_type === eachWhiteParticipant.user_type);
    if (mappedClassParticipant) {
      eachWhiteParticipant.colorCode = mappedClassParticipant.colorCode;
    }
  })
}

export const formatWhiteboardRoom = (_whiteboard: any, userInfo: any, _classdetails: ILiveClass) => {
  formatWhiteboardParticipant(_whiteboard, _classdetails);
  let _whiteboardSetting: IWhiteBoardInfo = {
    whiteboardConfig: _whiteboard,
    roomType: _whiteboard.is_global ? 'DEFAULT' : (_whiteboard.created_by_teacher && _whiteboard.created_by_teacher.id) === userInfo.id ? 'OWN' : 'OTHER',
    whiteBoardSdk: null,
    whiteBoardRoom: null,
    undoSteps: 0,
    redoSteps: 0,
    cameraZoomScale: 1,
    // whiteBoardRoomWriteMode: checkIsWhiteboardWriteMode(_whiteboard, _whiteboard.participants, userInfo),
    // whiteBoardRoomAdminMode: checkIsWhiteboardAdminMode(_whiteboard, _whiteboard.participants, userInfo),
  }
  return _whiteboardSetting;
}

export const formatWhiteboardRoomWithPreserveSetting = (_existingwhiteboard: IWhiteBoardInfo, _whiteboard: any, userInfo: any, _classdetails: ILiveClass) => {
  formatWhiteboardParticipant(_whiteboard, _classdetails);
  _existingwhiteboard.whiteboardConfig = _whiteboard;
  _existingwhiteboard.roomType = _whiteboard.is_global ? 'DEFAULT' : (_whiteboard.created_by_teacher && _whiteboard.created_by_teacher.id) === userInfo.id ? 'OWN' : 'OTHER';
  // _existingwhiteboard.whiteBoardRoomWriteMode = checkIsWhiteboardWriteMode(_whiteboard, _whiteboard.participants, userInfo);
  // _existingwhiteboard.whiteBoardRoomAdminMode = checkIsWhiteboardAdminMode(_whiteboard, _whiteboard.participants, userInfo);
  return _existingwhiteboard;
}

export const checkAndUpdateWhiteboardExists = (currentWhiteboardRoomLists: IWhiteBoardInfo[], whiteboardInfo: IWhiteboardConfig, userInfo: any, _classdetails: ILiveClass) => {
  const isWhiteboard = currentWhiteboardRoomLists.find((eachRoom) => eachRoom.whiteboardConfig.id === whiteboardInfo.id);
  const isWhiteboardIndex = currentWhiteboardRoomLists.findIndex((eachRoom) => eachRoom.whiteboardConfig.id === whiteboardInfo.id);
  if (!isWhiteboard) {
    currentWhiteboardRoomLists.push(formatWhiteboardRoom(whiteboardInfo, userInfo, _classdetails));
  }
  else {
    currentWhiteboardRoomLists.splice(isWhiteboardIndex, 1, formatWhiteboardRoomWithPreserveSetting(isWhiteboard, whiteboardInfo, userInfo, _classdetails));
  }
  return currentWhiteboardRoomLists;
}

export const checkRightLeftScrollPosition = (scrollWidth: number, viewportWidth: number, scrollLeft: number) => {
  let scrolllPosition = { rightEnd: false, leftEnd: false };
  if (Math.ceil(scrollWidth - viewportWidth) === 0) {
      scrolllPosition = { rightEnd: false, leftEnd: false };
  }
  else if (Math.ceil(scrollWidth - viewportWidth) === Math.ceil(scrollLeft)) {
      scrolllPosition = { rightEnd: false, leftEnd: true };
  }
  else if (scrollLeft <= 0) {
      scrolllPosition = { rightEnd: true, leftEnd: false };
  }
  else {
      scrolllPosition = { rightEnd: true, leftEnd: true };
  }
  return scrolllPosition;
};

export const getUniqueFromArrayOfObj = (listData: any[]) => {
  return listData.filter((v, i, a) =>{ return a.findIndex(x => x.id == v.id) === i});
}

export const getUniqueFromArray1D = (listData: any[]) => {
  return Array.from(new Set(listData));
}

export const generateUniqueId = () => {
  return (Math.random().toString(36).substring(2, 11));
}

export const ngForTrackByIdFunc = (index: number, value: any) => {
  return value.id;
}

export const isBothObjectsAreEqual = (o1: any, o2: any) => (
  typeof o1 === 'object' && o1 && Object.keys(o1).length > 0 ? (Object.keys(o1).length === Object.keys(o2).length) && Object.keys(o1).every(p => (isBothObjectsAreEqual(o1[p], o2[p]))) : o1 === o2
);

export const isBothArraysAreIdentical = (array1: any[], array2: any[]) => (
  (array1.length == array2.length) && array1.every((element: any, index: number) => (isBothObjectsAreEqual(element, array2[index])))
);

export const arrayGroupBy = (_data_list, keyName) => (
  _data_list.reduce((rv, x) => {
    (rv[x[keyName]] = rv[x[keyName]] || []).push(x);
    return rv;
  }, {})
)

export const chunkExistingArray = (_data_list, _chunk_size = 10) => {
  const _chunk_data_list = [];
  for (let i = 0; i < _data_list.length; i += _chunk_size) {
    _chunk_data_list.push(_data_list.slice(i, i + _chunk_size));
  }
  return _chunk_data_list;
}

export const removeEmptyValuesFromArray = (_data_list) => {
  return _data_list.filter((f: any) => !!f);
}

export const getMentionedIdsFromEditorContent = (_editor_text: string) => {
  const dyna_elem = document.createElement('div');
  dyna_elem.innerHTML = _editor_text;
  const mentioned_ids = [];
  dyna_elem.querySelectorAll('[data-mention]').forEach((elem: any) => {
    if (elem.dataset.id) {
      mentioned_ids.push(+elem.dataset.id);
    }
  })
  dyna_elem.remove();
  return getUniqueFromArray1D(mentioned_ids);
}

export const getChannelMemberRoleDetail = (_my_member_role: string) => {
  const _member_current_role = CHANNEL_MEMBER_ROLE_EXCEPT_LEADER_OPTIONS.find((_member_role) => _member_role.id === _my_member_role);
  if (_member_current_role) {
    return _member_current_role;
  }
  return null;
}

// export const isBothObjectsAreEqual = (o1: any, o2: any) => {
//   if (typeof o1 === 'object' && o1 && Object.keys(o1).length > 0) {
//     console.log('AAAAA', o1, o2);
//     if (Object.keys(o1).length === Object.keys(o2).length) {
//       console.log('BBBB');
//       const a = Object.keys(o1).every(p => {
//         console.log('DDD', o1[p], o2[p]);
//         return isBothObjectsAreEqual(o1[p], o2[p]);
//       })
//       console.log('AA', a);
//       return a;
//     }
//     return false;
//   } 
//   else {
//     console.log('CCC', o1);
//     return o1 === o2;
//   }
// }

// export const isBothArraysAreIdentical = (array1, array2) => (
//   (array1.length == array2.length) && array1.every((element: any, index: number) => {
//     const ab = isBothObjectsAreEqual(element, array2[index]);
//     console.log('AABBB', ab);
//     return ab;
//   })
// );