import { Injectable } from '@angular/core';
import { environment } from '@teacherapp_env/environment';
import { io, Socket } from 'socket.io-client';
import { LocalService } from '../local.service';
import { IChannelMemberWithInlineUser, IChannelMessage } from '@teacherapp_core/interfaces/channel-data.interface';
import { BehaviorSubject } from 'rxjs';
import { ITeamInfoMin } from '@teacherapp_core/interfaces/shared.interface';

@Injectable({
  providedIn: 'root'
})
export class SocketRealtimeCommunicationService {

  env = environment;

  socketConfig = {
    url: this.env.socket.base_url,
    path: this.env.socket.base_path,
    transports: ['websocket', 'polling'],
  };

  mySocketRef: Socket = null;
  mySocketId = null;

  globalRoomName = '';

  newMessageReceivedEvent = new BehaviorSubject<any>(null);
  newMessageReceivedEvent$ = this.newMessageReceivedEvent.asObservable();

  newReactionReceivedEvent = new BehaviorSubject<any>(null);
  newReactionReceivedEvent$ = this.newReactionReceivedEvent.asObservable();

  memberModificationReceivedEvent = new BehaviorSubject<any>(null);
  memberModificationReceivedEvent$ = this.memberModificationReceivedEvent.asObservable();

  pinnedMessageModificationReceivedEvent = new BehaviorSubject<any>(null);
  pinnedMessageModificationReceivedEvent$ = this.pinnedMessageModificationReceivedEvent.asObservable();

  newPersonalRoomReceivedEvent = new BehaviorSubject<any>(null);
  newPersonalRoomReceivedEvent$ = this.newPersonalRoomReceivedEvent.asObservable();

  newGlobalRoomReceivedEvent = new BehaviorSubject<any>(null);
  newGlobalRoomReceivedEvent$ = this.newGlobalRoomReceivedEvent.asObservable();

  latestActiveChannelRoomInfo = new BehaviorSubject<any>(null);
  latestActiveChannelRoomInfo$ = this.latestActiveChannelRoomInfo.asObservable();

  constructor(
    private localService: LocalService,
  ) {
    this.initSocketConnection();
  }

  updateNewMessageReceivedEventHandler(_msg_event: any) {
    this.newMessageReceivedEvent.next(_msg_event);
  }

  updateNewReactionReceivedEventHandler(_reaction_event: any) {
    this.newReactionReceivedEvent.next(_reaction_event);
  }

  updatememberModificationReceivedEventHandler(_member_event: any) {
    this.memberModificationReceivedEvent.next(_member_event);
  }

  updatePinnedMessageModificationReceivedEventHandler(_pinned_event: any) {
    this.pinnedMessageModificationReceivedEvent.next(_pinned_event);
  }

  updateNewPersonalRoomReceivedEventHandler(_event_detail: any) {
    this.newPersonalRoomReceivedEvent.next(_event_detail);
  }

  updateNewGlobalRoomReceivedEventHandler(_event_detail: any) {
    this.newGlobalRoomReceivedEvent.next(_event_detail);
  }

  updateActiveChannelRooMInfoHandler(_room_detail: any) {
    this.latestActiveChannelRoomInfo.next(_room_detail);
  }

  initSocketConnection() {
    const _current_user_id = this.localService.getUserIdLocalStorage();
    if (_current_user_id) {
      this.mySocketRef = io(this.socketConfig.url, {
        path: this.socketConfig.path,
        query: { uid: _current_user_id },
        transports: this.socketConfig.transports,
        upgrade: false,
      });
      console.log('MY SOCKERT REF', this.mySocketRef);
      if (this.mySocketRef) {
        this.listenForSocketConnectionEvents();
      }
    }
  }

  listenForSocketConnectionEvents() {
    this.mySocketRef.on("connect", () => {
      console.log('SOCKET CONNECTED', this.mySocketRef.id);
      console.log( this.mySocketRef);
      this.mySocketId = this.mySocketRef.id;
      this.connectToGlobalRoom();
      const _latest_channel_room_config = this.latestActiveChannelRoomInfo.value;
      if (_latest_channel_room_config) {
        this.joinInPerticularRoom(_latest_channel_room_config);
      }
    });

    this.mySocketRef.on("disconnect", () => {
      console.log('SOCKET DISCONNECTED', this.mySocketRef.id);
      this.mySocketId = null;
    });

    this.mySocketRef.on('received_channel_message', (response: any) => {
      this.updateNewMessageReceivedEventHandler(response);
      console.log('LISTEN CHANNEL MESSAGE RECEIVED EMITTER', response);
    })
  
    this.mySocketRef.on('received_message_reaction', (response: any) => {
      this.updateNewReactionReceivedEventHandler(response);
      console.log('LISTEN MESSAGE REACTION RECEIVED EMITTER', response);
    })

    this.mySocketRef.on('received_channel_member_modification', (response: any) => {
      console.log('LISTEN MEMBER RECEIVED EMITTER IN CHANNEL @@ socketRealTime', response);
      this.updatememberModificationReceivedEventHandler(response);
    })

    this.mySocketRef.on('received_channel_pinned_message_modification', (response: any) => {
      this.updatePinnedMessageModificationReceivedEventHandler(response);
      console.log('LISTEN PINNED RECEIVED EMITTER', response);
    })

    this.mySocketRef.on('received_personal_room_message', (response: any) => {
      this.updateNewPersonalRoomReceivedEventHandler({ action_type: 'RECEIVED_CHANNEL_MESSAGE_EVENT', ...response });
      console.log('LISTEN GLOBAL CHAT RECEIVED EMITTER', response);
    })

    this.mySocketRef.on('received_personal_room_channel_msg_reaction', (response: any) => {
      this.updateNewPersonalRoomReceivedEventHandler({ action_type: 'RECEIVED_CHANNEL_MESSAGE_REACTION_EVENT', ...response });
      console.log('LISTEN GLOBAL MESSAGE REACTION RECEIVED EMITTER', response);
    })

    this.mySocketRef.on('received_personal_room_channel_member_modification', (response: any) => {
      console.log('LISTEN GLOBAL CHANNEL MEMBER RECEIVED EMITTER @@ socketRealTime', response);
      this.updateNewPersonalRoomReceivedEventHandler({ action_type: 'RECEIVED_CHANNEL_MEMBER_MODIFY_EVENT', ...response });
    })

    this.mySocketRef.on('received_global_room_user_status_modification', (response: any) => {
      this.updateNewGlobalRoomReceivedEventHandler({ action_type: 'RECEIVED_USER_STATUS_MODIFY_EVENT', ...response });
      console.log('LISTEN GLOBAL USER STATUS RECEIVED EMITTER', response);
    })

    this.mySocketRef.on('received_global_room_channel_modification', (response: any) => {
      this.updateNewGlobalRoomReceivedEventHandler({ action_type: 'RECEIVED_CHANNEL_MODIFY_EVENT', ...response });
      console.log('LISTEN GLOBAL CHANNEL MODIFY RECEIVED EMITTER', response);
    })
  }

  connectToGlobalRoom() {
    const _room_info = {
      room_selection: 'AUTO',
      room_type: 'DEFAULT',
      room_kind: 'DEFAULT'
    };
    this.joinInPerticularRoom(_room_info);
  }

  generateSpecificRoomName(_item_id: any, _room_type: 'CHANNEL' = 'CHANNEL', _room_kind: 'DEFAULT' = 'DEFAULT') {
    return `${_room_type}_${_room_kind}_${_item_id}`;
  }

  connectToSpecificRoom(_item_id: any, _room_type: 'CHANNEL' = 'CHANNEL', _room_kind: 'DEFAULT' = 'DEFAULT') {
    const _room_info = {
      room_selection: 'SPECIFIC',
      room_type: _room_type,
      room_kind: _room_kind,
      channel_id: _room_type === 'CHANNEL' ? _item_id : '',
      room_name: this.generateSpecificRoomName(_item_id, _room_type, _room_kind),
    };
    this.updateActiveChannelRooMInfoHandler(_room_info);
    this.joinInPerticularRoom(_room_info);
  }

  joinInPerticularRoom(_room_info: any) {
    this.mySocketRef.emit('join_room', _room_info, (response: any) => {
      if (response.room_data && response.room_data.room_type === 'DEFAULT' && response.room_data.room_kind === 'DEFAULT') {
        this.globalRoomName = response.room_data.room_name;
      }
      console.log('RESPONSE AFTER JOIN', response);
    });
  }

  disconnectFromSpecificRoom(_item_id: any, _room_type: 'CHANNEL' = 'CHANNEL', _room_kind: 'DEFAULT' = 'DEFAULT' ) {
    const _room_info = {
      room_selection: 'SPECIFIC',
      room_type: _room_type,
      room_kind: _room_kind,
      room_name: this.generateSpecificRoomName(_item_id, _room_type, _room_kind),
    };
    this.updateActiveChannelRooMInfoHandler(null);
    this.mySocketRef.emit('leave_room', _room_info, (response: any) => {
      console.log('RESPONSE AFTER LEAVE', response);
    });
  }

  sendMessageInPerticularChannelRoom(_channel_id, _message_payload: any) {
    const _message_socket_payload = {
      room_name: this.generateSpecificRoomName(_channel_id),
      channel_id: _channel_id,
      ..._message_payload,
      // action_mode: _message_payload.action_mode,
      // message_feed: _message_payload.message_feed,
      // members_list: _message_payload.members_list,
      // team_detail: _message_payload.team_detail,
    };
    this.mySocketRef.emit('send_channel_message', _message_socket_payload, (response: any) => {
      console.log('RESPONSE AFTER Sending Message', response);
    });
  }

  sendReactionMsgInPerticularChannelRoom(_channel_id, _reaction_action_detail: any) {
    const _message_socket_payload = {
      room_name: this.generateSpecificRoomName(_channel_id),
      ..._reaction_action_detail,
      // action_mode: _reaction_action_detail.action_mode,
      // reaction_feed: _reaction_action_detail.reaction_feed,
      // parent_message_id: _reaction_action_detail.parent_message_id,
      // team_detail: _reaction_action_detail.team_detail,
      // channel_detail: _reaction_action_detail.channel_detail,
      // topic_detail: _reaction_action_detail.topic_detail,
      // members_list: _reaction_action_detail.members_list,
    };
    this.mySocketRef.emit('send_message_reaction', _message_socket_payload, (response: any) => {
      console.log('RESPONSE AFTER Sending Message Reaction', response);
    });
  }

  sendChannelMemberModificationInPerticularChannelRoom(_channel_id, _member_action_detail: any) {
    if (_member_action_detail.member_feed || _member_action_detail.exclude_member_feed) {
      const _member_socket_payload = {
        room_name: this.generateSpecificRoomName(_channel_id),
        ..._member_action_detail,
        channel_detail: _member_action_detail.member_feed ? _member_action_detail.member_feed.channel_id : _member_action_detail.exclude_member_feed.channel_id,
        // action_mode: _member_action_detail.action_mode,
        // member_feed: _member_action_detail.member_feed,
        // exclude_member_feed: _member_action_detail.exclude_member_feed,
        // team_id: _member_action_detail.team_id,
      };
      this.mySocketRef.emit('send_channel_member_modification', _member_socket_payload, (response: any) => {
        console.log('RESPONSE AFTER Sending Channel Member', response);
      });
    }
  }

  sendPinnedMessageModificationInPerticularChannelRoom(_channel_id, _pinned_action_detail: any) {
    const _pinned_socket_payload = {
      room_name: this.generateSpecificRoomName(_channel_id),
      ..._pinned_action_detail,
      // action_mode: _pinned_action_detail.action_mode,
      // pinned_message_feed: _pinned_action_detail.pinned_message_feed,
    };
    this.mySocketRef.emit('send_channel_pinned_message_modification', _pinned_socket_payload, (response: any) => {
      console.log('RESPONSE AFTER Sending Channel Pinned Message', response);
    });
  }

  sendUserOnlineStatusModificationInGlobalRoom(_user_status_detail: any) {
    const _user_status_socket_payload = {
      room_name: this.globalRoomName,
      ..._user_status_detail,
      // action_mode: _user_status_detail.action_mode,
      // status_feed: _user_status_detail.status_feed,
    };
    this.mySocketRef.emit('send_user_status_modification', _user_status_socket_payload, (response: any) => {
      console.log('RESPONSE AFTER Sending user status', response);
    });
  }

  sendChannelModificationInGlobalRoom(_channel_detail: any) {
    const _channel_status_socket_payload = {
      room_name: this.globalRoomName,
      ..._channel_detail,
      // action_mode: _channel_detail.action_mode,
      // channel_feed: _channel_detail.channel_feed,
    };
    this.mySocketRef.emit('send_channel_modification', _channel_status_socket_payload, (response: any) => {
      console.log('RESPONSE AFTER Sending channel', response);
    });
  }
}
