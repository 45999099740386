import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITeam } from '@teacherapp_core/interfaces/organization-team.interface';
// import { STAFF_ORG_PERMISSION } from '@teacherapp_core/common/staff-orgamization-permission-mock';
import { IPageOperationSetting, IUserAssignedPermission, IUserPermissionInfo } from '@teacherapp_core/interfaces/permission-setting.interface';
import { CheckStaffPermissionPipe } from '@teacherapp_core/pipes/check-staff-permission/check-staff-permission.pipe';
import { environment } from '@teacherapp_env/environment';
import { BehaviorSubject } from 'rxjs';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionHandlerService {

  env = environment;
  baseUrl = this.env.base_url;

  moduleGroupPermissionSetttings = new BehaviorSubject<any[]>([]);
  readonly groupPermissionSettings$ = this.moduleGroupPermissionSetttings.asObservable();

  // currentUserPermission = STAFF_ORG_PERMISSION;
  currentUserPermission = [];
  userGroupAllowedPermissionSettings: IUserAssignedPermission = {};

  currentSelectedTeamConfig = new BehaviorSubject<ITeam>(null);
  readonly currentSelectedTeamConfig$ = this.currentSelectedTeamConfig.asObservable();

  userGroupAssignedPermissionSettings = new BehaviorSubject<IUserAssignedPermission>({});
  readonly userGroupAssignedPermissionSettings$ = this.userGroupAssignedPermissionSettings.asObservable();

  constructor(
    private http: HttpClient,
    private localService: LocalService,
  ) {

  }

  // updatePermissionGroupSettings(permissionGroup: any[]) {
  //   this.moduleGroupPermissionSetttings.next(permissionGroup);
  // }

  updateUserGroupAssignedSettings(permissionInfo: any) {
    this.userGroupAssignedPermissionSettings.next(permissionInfo);
  }

  updateCurrentSelectedTeamConfig(teamInfo: any) {
    this.currentSelectedTeamConfig.next(teamInfo);
  }

  fetchModuleGroupAndOperations() {
    return this.http.get(`${this.baseUrl}/org/appgroup_module`);
  }

  // callGroupPermissionApi() {
  //   this.fetchModuleGroupAndOperations().subscribe(
  //     (response: any) => {
  //       this.updatePermissionGroupSettings(response);
  //     },
  //     (error: any) => {
  //       this.updatePermissionGroupSettings([]);
  //     }
  //   )
  // }

  formatModuleInfo(eachPermission: any, eachModule: any) {
    let obj: IUserPermissionInfo = {
      moduleGroup: eachModule.app_module_id.module_group.module_group_name,
      moduleName: eachModule.app_module_id.module_name,
      allowedPermission: typeof eachModule.permission === 'string' ? [eachModule.permission] : eachModule.permission,
      possiblePermissions: eachModule.app_module_id.permission,
      permissionLevel: eachPermission.auth_group_id.auth_group_level,
      organizationId: eachPermission.org_id ? eachPermission.org_id.id : null,
      branchId: eachPermission.branch_id ? eachPermission.branch_id.id : null,
    };
    return obj;
  }

  formatUserAssignedPermissionInfo() {
    this.userGroupAllowedPermissionSettings = {};
    this.currentUserPermission.forEach((eachPermission) => {
      eachPermission.auth_group_id.group_module_link.forEach((eachModule) => {
        if (eachModule.app_module_id.module_name in this.userGroupAllowedPermissionSettings) {
          this.userGroupAllowedPermissionSettings[eachModule.app_module_id.module_name].push(this.formatModuleInfo(eachPermission, eachModule));
        }
        else {
          this.userGroupAllowedPermissionSettings[eachModule.app_module_id.module_name] = [this.formatModuleInfo(eachPermission, eachModule)];   
        }
      })
    });
  }

  fetchStaffAllPermissions(params: any) {
    return this.http.get(`${this.baseUrl}/org/get_permissions_group`, { params });
  }

  fetchMyPermissionsAsTeamMember(data: any) {
    return this.http.post(`${this.baseUrl}/org/organization_auth_operations`, data);
  }

  // formatAndUpdateStaffPermission(params: any) {
  //   this.fetchStaffAllPermissions(params).subscribe(
  //     (response: any) => {
  //       this.currentUserPermission = response;
  //       this.formatUserAssignedPermissionInfo();
  //       console.log(this.userGroupAllowedPermissionSettings);
  //       this.updateUserGroupAssignedSettings(this.userGroupAllowedPermissionSettings);
  //     },
  //     (error: any) => {
  //       this.currentUserPermission = [];
  //       this.userGroupAllowedPermissionSettings = {};
  //       this.updateUserGroupAssignedSettings(this.userGroupAllowedPermissionSettings);
  //     }
  //   )
  // }

  formatAndUpdateStaffPermissionAfterAPiResponse(response: any) {
    this.currentUserPermission = response;
    this.formatUserAssignedPermissionInfo();
    console.log(this.userGroupAllowedPermissionSettings);
    this.updateUserGroupAssignedSettings(this.userGroupAllowedPermissionSettings);
  }

  formatAndUpdateSpecificTeamRelatedPermissionWithAPiResponse(response: any) {
    const _current_my_team_assigned_permission = [
      {
        auth_group_id: response,
      }
    ];
    this.currentUserPermission = _current_my_team_assigned_permission;
    this.formatUserAssignedPermissionInfo();
    console.log(this.userGroupAllowedPermissionSettings);
    this.updateUserGroupAssignedSettings(this.userGroupAllowedPermissionSettings);
  }

  resetAndUpdateSpecificTeamRelatedPermissionWithAPiResponse() {
    this.userGroupAllowedPermissionSettings = {};
    console.log(this.userGroupAllowedPermissionSettings);
    this.updateUserGroupAssignedSettings(this.userGroupAllowedPermissionSettings);
  }

  checkViewPermissionForLearningPathPage() {
    const userAssignedPermission = this.userGroupAssignedPermissionSettings.value;
    const _org_student_listing_permission_module: IPageOperationSetting = {
      moduleGroup: 'LIBRARY',
      moduleName: 'LEARNINGPATH_MANAGEMENT',
      permissionLevel: 'default',
      permission: 'VIEW',
    };
    const permissionAllowed = new CheckStaffPermissionPipe(this.localService, this).transform(_org_student_listing_permission_module, userAssignedPermission['LEARNINGPATH_MANAGEMENT'], null);
    return permissionAllowed;
  }

  checkViewPermissionForSuperbookPage(): boolean {
    const userAssignedPermission = this.userGroupAssignedPermissionSettings.value;
    const _org_student_listing_permission_module: IPageOperationSetting = {
      moduleGroup: 'LIBRARY',
      moduleName: 'SUPERBOOK_MANAGEMENT',
      permissionLevel: 'default',
      permission: 'VIEW',
    };
    const permissionAllowed = new CheckStaffPermissionPipe(this.localService, this)
    .transform(_org_student_listing_permission_module, userAssignedPermission['SUPERBOOK_MANAGEMENT'], null);
    return permissionAllowed;
  }

  checkViewPermissionForMainTopicPage(): boolean {
    const userAssignedPermission = this.userGroupAssignedPermissionSettings.value;
    const _org_student_listing_permission_module: IPageOperationSetting = {
      moduleGroup: 'LIBRARY',
      moduleName: 'MAINTOPIC_MANAGEMENT',
      permissionLevel: 'default',
      permission: 'VIEW',
    };
    const permissionAllowed = new CheckStaffPermissionPipe(this.localService, this)
    .transform(_org_student_listing_permission_module, userAssignedPermission['MAINTOPIC_MANAGEMENT'], null);
    return permissionAllowed;
  }

  checkViewPermissionForSubTopicPage(): boolean {
    const userAssignedPermission = this.userGroupAssignedPermissionSettings.value;
    const _org_student_listing_permission_module: IPageOperationSetting = {
      moduleGroup: 'LIBRARY',
      moduleName: 'SUBTOPIC_MANAGEMENT',
      permissionLevel: 'default',
      permission: 'VIEW',
    };
    const permissionAllowed = new CheckStaffPermissionPipe(this.localService, this)
    .transform(_org_student_listing_permission_module, userAssignedPermission['SUBTOPIC_MANAGEMENT'], null);
    return permissionAllowed;
  }

  checkViewPermissionForLecturePage(): boolean {
    const userAssignedPermission = this.userGroupAssignedPermissionSettings.value;
    const _org_student_listing_permission_module: IPageOperationSetting = {
      moduleGroup: 'LIBRARY',
      moduleName: 'LECTURE_MANAGEMENT',
      permissionLevel: 'default',
      permission: 'VIEW',
    };
    const permissionAllowed = new CheckStaffPermissionPipe(this.localService, this)
    .transform(_org_student_listing_permission_module, userAssignedPermission['LECTURE_MANAGEMENT'], null);
    return permissionAllowed;
  }

  checkViewPermissionForLectureComponentPage(): boolean {
    const userAssignedPermission = this.userGroupAssignedPermissionSettings.value;
    const _org_student_listing_permission_module: IPageOperationSetting = {
      moduleGroup: 'LIBRARY',
      moduleName: 'LECTURE_COMPONENT_MANAGEMENT',
      permissionLevel: 'default',
      permission: 'VIEW',
    };
    const permissionAllowed = new CheckStaffPermissionPipe(this.localService, this)
    .transform(_org_student_listing_permission_module, userAssignedPermission['LECTURE_COMPONENT_MANAGEMENT'], null);
    return permissionAllowed;
  }
}
